.collaborateModal {
  @borderColor: @c-pearl;
  @borderRadius: 2px;
  @space: 1.111rem;

  background: @c-white;
  color: @c-black;

  .modalHeader {
    box-shadow: 0 1px 0 @c-pearl;
  }

  .modalContent {
    background: none;
    min-height: 200px;
  }

  form {
    display: flex;
  }

  .inputBox {
    color: @c-denim;
    flex: 1;
  }

  .combobox {
    border-radius: @borderRadius;
    font-size: 1.444rem;

    &__control {
      border-color: @borderColor;
      border-radius: @borderRadius;
    }
    &__indicators {
      display: none;
    }
    &__placeholder {
      margin-left: 3px;
    }
    &__value-container {
      padding: 0;
      padding-left: @space;
    }

    &__multi-value[class] {
      background-color: @c-white;
      margin: 0;
      margin-right: 0.5 * @space;

      &:first-child {
        margin-left: (-0.5 * @space);
      }
      .avatar,
      .email {
        display: none;
      }
    }
    &__multi-value__label,
    &__multi-value__remove {
      border-color: @borderColor;
      border-radius: 1px;
      padding: 0 (0.5 * @space);
    }
    &__multi-value__label {
      align-items: center;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: 0;
      color: @c-black;
      display: flex;
      font-size: 1.222rem;
      height: 2.333rem;
      line-height: normal;
    }
    &__multi-value__remove {
      border-bottom-left-radius: 0;
      border-left: 0;
      border-top-left-radius: 0;
      color: @c-denimLight;
      padding: 0;

      svg {
        width: 16px;
      }

      &:hover {
        background: none;
        border-color: @borderColor;
      }
    }

    &__menu {
      border: 1px solid @c-silver;
      border-radius: inherit;
      box-shadow: none;
      display: block;
      font-size: 1.222rem;
      font-weight: @f-medium;
    }
    &__menu-list {
      margin: 0;
      padding: 0;
    }
    &__option {
      background: none;
      border: 1px solid transparent;
      display: flex;
      padding-left: @space;
      padding-right: @space;
      transition: border-color 0.2s;

      & + .combobox__option {
        margin-top: -1px;
      }

      &:first-child {
        border-top-left-radius: 0.5 * @borderRadius;
        border-top-right-radius: 0.5 * @borderRadius;
      }
      &:last-child {
        border-bottom-left-radius: 0.5 * @borderRadius;
        border-bottom-right-radius: 0.5 * @borderRadius;
      }

      &--is-focused {
        border-color: @c-primary;
      }
    }

    .avatar {
      @size: 3.333rem;

      display: grid;
      font-size: 1.667rem;
      font-weight: @f-bold;
      height: @size;
      line-height: 1;
      margin-right: @space;
      place-items: center;
      width: @size;
    }

    .email {
      color: #979797;
      display: block;
    }
  }

  .button {
    border-radius: @borderRadius;
    margin-left: @space;
  }

  .avatarList {
    margin-bottom: 0;
    margin-top: 1.5 * @space;
    padding-left: 0;

    li {
      align-items: center;
      display: flex;
      justify-content: space-between;

      & + li {
        margin-top: @space;
      }
      & > * {
        align-items: inherit;
        display: inherit;
        font-size: 1.333rem;
      }
      & > :last-child {
        text-transform: lowercase;
      }

      .avatar {
        margin-right: @space;
      }

      .button {
        @size: 1.778rem;

        background-color: transparent;
        border: 0;
        border-radius: @borderRadius;
        box-shadow: none;
        color: @c-cloud;
        height: @size;
        margin-left: @space;
        padding: 0;
        width: @size;

        svg {
          fill: none;
          height: inherit;
          width: inherit;
        }

        &:hover {
          background-color: @c-error;
          color: @c-white;
        }
      }
    }
  }
}
