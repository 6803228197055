.hint {
  @backgroundColor: @c-black;
  @color: @c-white;

  --arrowBottom: auto;
  --arrowLeft: auto;
  --arrowRight: auto;
  --arrowTop: auto;
  --arrowTranslateX: 0;
  --arrowTranslateY: 0;

  animation: hintEnter 0.3s 0.8s both;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 4px;
  pointer-events: none;
  z-index: 1;

  &[class*="hidden"] {
    display: none;
  }

  &[class*="bottom"] {
    --arrowTop: 0;
    --arrowTranslateY: 10%;
  }
  &[class*="bottom"],
  &[class*="top"] {
    --arrowLeft: 50%;
    --arrowTranslateX: -50%;
  }
  &[class*="top"] {
    --arrowBottom: 0;
    --arrowTranslateY: -10%;
  }
  &[class*="left"] {
    --arrowRight: 0;
    --arrowTranslateX: -10%;
  }
  &[class*="left"],
  &[class*="right"] {
    --arrowTop: 50%;
    --arrowTranslateY: -50%;
  }
  &[class*="right"] {
    --arrowLeft: 0;
    --arrowTranslateX: 10%;
  }

  &-arrow {
    @size: 1.778rem;

    background-color: @backgroundColor;
    bottom: var(--arrowBottom);
    display: inline-block;
    height: @size;
    left: var(--arrowLeft);
    position: absolute;
    right: var(--arrowRight);
    top: var(--arrowTop);
    transform: translate(var(--arrowTranslateX), var(--arrowTranslateY))
      rotate(45deg);
    width: @size;
  }

  &-inner {
    background: @backgroundColor;
    border-radius: 2px;
    box-shadow: 0 4px 8px rgba(@backgroundColor, 0.25);
    color: @color;
    line-height: normal;
    padding: 0.667rem;
    position: relative;
  }

  &-label {
    & + .hint-key {
      margin-left: 0.889rem;
    }
  }

  &-key {
    background-color: rgba(@color, 0.25);
    border-radius: 2px;
    color: @color;
    display: inline-grid;
    font-family: inherit;
    font-size: 1.1em;
    min-width: 1.75em;
    padding: 2px 4px;
    place-items: center;
    text-transform: capitalize;

    & + .hint-key {
      margin-left: 0.444rem;
    }
  }
}

@keyframes hintEnter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
