// LEFT PANEL with list of assets
.scene-editor .left-panel {
  @controlSize: 3.889rem;

  background-color: @panelBackground;
  bottom: 0;
  display: flex;
  position: fixed;
  right: 0;
  top: @headerHeight;
  width: @panelWidth;

  .assets {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    width: 100%;
  }

  h2 {
    align-items: center;
    background-color: @c-secondary;
    color: @c-white;
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    height: @controlSize;
    justify-content: center;
    transition: background-color 0.6s;
  }

  .open-library-btn {
    align-items: center;
    color: inherit;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: @controlSize;

    svg {
      height: 45%;
      transition: transform 0.6s 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
  }

  .assets-list {
    flex: 1;
  }

  .walls {
    flex: 1 1 auto;
    overflow-y: auto;
    background-color: #fff;

    // WALL LABEL
    h3 {
      font-size: 1.4rem;
      line-height: 1.6rem;
      text-transform: uppercase;
      color: #474a5d;
      font-weight: @f-bold;
      padding: 0.6rem 0 0.6rem 4rem;
      margin: 0;
      background: @labelsBackground;
      font-size: 1.2rem;
      letter-spacing: 0.1rem;
      position: relative;
      cursor: pointer;

      a {
        float: right;
        min-width: 2.2rem;
        height: 2.2rem;
        text-align: center;
        box-sizing: border-box;
        margin: -0.3rem 0.7rem -0.5rem 1.5rem;
        padding-top: 0.3rem;
        border-radius: 0.3rem;
        color: @secondaryColor;
      }
      &.active a {
        background-color: @c-primary;
        color: @c-primaryContrast;
      }
      .icon-layers {
        position: absolute;
        left: 0.7rem;
        top: 0.6rem;
      }
    }
  }

  // content of Background tab
  .backgrounds {
    flex: 1 1 auto;
    overflow-y: auto;
    background: @labelsBackground;
    text-align: center;
    // height: 100%;
  }
  .backgrounds-empty {
    padding: 9rem 6rem;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.5);
  }
  .backgrounds-dnd-placeholder {
    padding: 9rem 6rem;
    font-size: 1.2rem;
    background-color: rgba(55, 139, 255, 0.5);
  }
  .backgrounds-asset {
    padding: 2rem 0;
    position: relative;
    width: 100%;
    text-align: left;

    .title {
      margin: 0 5rem;
      display: block;
    }
    .status {
      float: right;
      position: relative;
    }
    .type {
      position: absolute;
      left: 2rem;
      top: 2.1rem;
    }
    .remove {
      position: absolute;
      right: 2rem;
      top: 2.1rem;
      color: #000;

      &:hover {
        color: @alertColor;
      }
    }
    .wall-dimension-status-wrapper {
      float: right;
      margin: -0.2rem 0 0 1rem;
    }
  }

  .config {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 2.444rem 50px;
    padding: 2.222rem 20px;

    & > div {
      min-width: 6.667rem;
    }

    h3 {
      margin-bottom: 1px;
      white-space: nowrap;
    }
  }

  // TABS contains couple of <button> elements
  // 1. Safari tweaks
  .left-panel-tabs {
    background: @c-denim;
    color: @c-black;
    display: flex;
    font-size: 1.2rem;
    font-weight: @f-medium;
    height: 3rem;
    white-space: nowrap;

    button {
      border: 0;
      color: @secondaryContrastColor;
      flex: 1;
      height: 100%; /* 1 */
      padding: 0 3rem;
      width: 100%; /* 1 */

      & + button {
        border-left: 1px solid #ebebeb;
      }

      &:hover,
      &:active {
        background-color: lighten(desaturate(@secondaryColor, 40%), 20%);
      }
      &.active {
        background-color: @labelsBackground;
        color: @secondaryColor;
      }
    }
  }
}
