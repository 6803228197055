.tabs {
  flex: 0 0 100%;
  flex-direction: column;
  display: flex;
  position: relative;
  z-index: 2;
  padding: 0;
  overflow: hidden;

  .tabHeader {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 2px solid @primaryColor;
    padding: 2rem 2rem 0 2rem;
    user-select: none;
  }

  .tabHeading {
    flex: 0 0 auto;
    margin: 0 2rem 2rem 0;
    line-height: @input-height;
  }

  .tabButtons {
    flex: 0 0 auto;
    margin: 0 0 2rem 2rem;
  }

  .tabNav {
    flex: 0 1 auto;
    padding: 0;
    margin: 0 auto;
    max-width: 100%;
    display: flex;
    list-style: none;
    overflow: hidden;

    .item {
      flex: 0 1 auto;
      display: inline-flex;
      height: @input-height;
      box-sizing: content-box;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 3rem 2rem;
      font-weight: @f-bold;
      color: @c-denimLight;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: color 0.1s linear;
      cursor: pointer;

      &:hover {
        color: darken(@c-denimLight, 20%);
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 0.4rem;
        width: 100%;
        transform: scaleX(0);
        background-color: @c-primary;
        opacity: 0;
        transition: all 0.1s ease-in;
      }

      &.active {
        color: @c-primary;

        &::before {
          opacity: 1;
          transform: scaleX(1);
          transition: opacity 0s, transform 0.2s ease-out;
        }
      }
    }
  }

  .search {
    margin: 1.5rem 2rem 1.5rem 2rem;
    width: 30rem;
    flex: 0 0 auto;
    align-self: flex-end;
  }

  .returnButton {
    margin: 1.5rem 2rem 1.5rem 2rem;
    flex: 0 0 auto;
    align-self: flex-start;
  }

  .tabFilter {
    flex: 0 0 auto;
    margin-bottom: 0.5rem;

    .search {
      margin: 1.5rem 2rem 0 2rem;
      min-width: 20rem;
      width: auto;
      flex: 0 1 30rem;
      align-self: flex-start;
    }
  }

  .tabContent {
    flex: 1 1 100%;
    flex-direction: column;
    display: flex;
    overflow: hidden;

    .listContent {
      flex: 1;
    }

    .scrollContent {
      flex: 1;
      overflow: auto;
    }
  }

  // Custom fields filter
  .withCategoryWrapper {
    flex: 1;
    display: flex;
  }
}
