/**
 * Component Switch.
 */

// =============================================================================
// Switch
// =============================================================================

/**
 * 1. Designed as 13px.
 * 2. Designed as 36px, normalized.
 */

.swytch {
  --color--active: @c-blueDodger;
  --color--initial: #dadde6;
  --color-text--active: @c-white;
  --color-text--initial: @c-denim;

  --swytch-animation-duration: 0.3s;
  --swytch-animation-timing: @ease-out-circ;
  --swytch-background: @c-white;
  --swytch-border-radius: 4px;
  --swytch-border-size: 1px;
  --swytch-color: var(--color--initial);
  --swytch-font-size: 1.444rem; /* 1 */
  --swytch-font-weight: @f-medium;
  --swytch-height: var(--swytch-size, @input-height); /* 2 */
  --swytch-text-color: var(--color-text--initial);
  --swytch-width: 100px;

  background-color: var(--swytch-background);
  border: var(--swytch-border-size) solid var(--swytch-color);
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  font-size: var(--swytch-font-size);
  font-weight: var(--swytch-font-weight);
  height: var(--swytch-height);
  overflow: hidden;
  position: relative;
  transition-duration: var(--swytch-animation-duration);
  transition-property: border-color;
  transition-timing-function: var(--swytch-animation-timing);
  user-select: none;
  width: var(--swytch-width);

  input {
    height: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }
}

/**
 * States.
 */

/**
 * Switch, active.
 */

.swytch {
  &.is-active {
    --swytch-color: var(--color--active);

    [class*="handle"] {
      transform: translateX(0);
    }

    [class*="label"] {
      &:nth-of-type(1) {
        --swytch-text-color: var(--color-text--active);
      }
      &:nth-of-type(2) {
        --swytch-text-color: revert;
      }
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Label
// =============================================================================

.swytch__label {
  color: var(--swytch-text-color);
  display: grid;
  flex: 0 0 50%;
  place-items: center;
  position: relative;
  z-index: 1;

  &:nth-of-type(2) {
    --swytch-text-color: var(--color-text--active);
  }
}

// Handle
// =============================================================================

.swytch__handle {
  background: var(--swytch-color);
  border-radius: calc(var(--swytch-border-radius) - 1px);
  display: block;
  flex: 0 0 50%;
  height: 100%;
  position: absolute;
  transition-duration: var(--swytch-animation-duration);
  transition-property: background-color, transform;
  transition-timing-function: var(--swytch-animation-timing);
  transform: translateX(100%);
  width: 50%;
}
