.wall-dimension-status-wrapper {
  line-height: 0;
  display: inline-block;
  padding: 0 5px;
}

.wall-dimension-status {
  cursor: help;
  pointer-events: auto;
  display: inline-block;

  & > * {
    pointer-events: none;
  }

  &.warning {
    color: @warningColor;
    fill: @warningColor;
  }

  &.ok {
    color: @successColor;
    fill: @successColor;
  }

  &.info {
    color: @c-primary;
  }
}

.rc-tooltip.instances-wall-statuses {
  opacity: 1;

  .rc-tooltip-arrow {
    opacity: 0;
  }
  .rc-tooltip-inner {
    background: @c-white;
    border: none;
    padding: 12px 24px 12px 16px;
    border-radius: 0;
    max-height: 70vh;
    overflow-y: auto;
    box-shadow: @boxShadowTooltip;

    .list {
      .item {
        white-space: nowrap;
        color: @c-primaryText;
        line-height: 20px;
        padding: 3px 0;

        .wall-dimension-status {
          margin-right: 10px;
          vertical-align: top;
          display: inline-block;

          svg {
            vertical-align: top;
          }
        }
      }
    }
  }
}
