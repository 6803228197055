// CONTENT is everything except header and navigation bar
.scene-editor > .content {
  display: flex;
  flex: 1;
  height: 0;

  &.contentPreview {
    justify-content: center;
    align-items: center;

    div {
      font-size: 1.5em;
      animation-name: pulse_animation;
      animation-duration: 1500ms;
      animation-iteration-count: infinite;
    }
  }

  &.has-panel-open {
    .asset-library {
      transform: translateX(0);
    }

    .left-panel {
      h2 {
        background-color: @c-error;
      }
    }

    .open-library-btn {
      svg {
        transform: rotate(calc(-1 * (1 * 360deg + 45deg)));
      }
    }
  }

  @keyframes pulse_animation {
    0% {
      opacity: 0.25;
    }
    25% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.25;
    }
  }
}
