.customFieldsCategorySelector {
  min-width: 150px;
  max-width: 300px;
  padding: 10px;
  background: #fff;
  box-shadow: 1px 0px 0px #eee;

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  ul {
    margin-top: 10px;
  }

  li {
    list-style: none;
    line-height: 24px;
    margin-left: 10px;
    position: relative;
    top: -5px;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    span {
      font-size: 13px;
      font-weight: 500;
      user-select: none;
    }
  }

  ul > li.selected > span {
    text-decoration: underline;
    font-weight: 600;
  }
}
