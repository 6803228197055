.sidebar {
  flex: 0 0 @sidebarWidth;
  background-color: @c-contentBackground;
  border-right: 1px solid @c-contentBorder;
  box-sizing: border-box;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: flex-basis 0.3s;
  transform: translateZ(0);
  z-index: @z-sidebar;

  .navigation {
    margin-top: 1.5rem;
    overflow-y: auto;
    user-select: none;

    a {
      @height: 5rem;
      height: @height;
      line-height: @height;
      display: block;
      color: @c-primaryText;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 1rem 0 6rem;
      position: relative;
      margin-bottom: 2rem;
      position: relative;
      transition: all 0.1s linear;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 0.3rem;
        height: 100%;
        transform: scaleY(0);
        background-color: @c-primary;
        opacity: 0;
        transition: all 0.1s ease-in;
      }

      &:hover {
        text-decoration: none;
        background-color: @c-primaryLight;
      }
      &.active {
        color: @c-primary;

        &:after {
          opacity: 1;
          transform: scaleY(1);
          transition: opacity 0s, transform 0.2s ease-out;
        }
        svg {
          opacity: 0;

          &.iconActive {
            opacity: 1;
          }
        }
      }

      b {
        position: absolute;
        left: @sidebarWidthCollapsed - 1rem;
        top: 0;
        font-weight: @f-medium;
        transition-duration: 0.3s, 0.15s;
        transition-property: transform, opacity;
        transition-timing-function: ease-out, linear;
      }

      svg {
        @width: 24px;
        width: @width;
        left: calc((@sidebarWidthCollapsed - @width) / 2);
        top: 1.1rem;
        position: absolute;
        fill: currentColor;
        transition: opacity 0.12s linear;

        &.iconActive {
          opacity: 0;
        }
      }
    }
  }

  .collapseButton {
    position: fixed;
    right: -1.2rem;
    top: @headerHeight + 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 1px solid @c-contentBorder;
    box-sizing: border-box;
    transform: translateZ(0);
    background-color: @c-contentBackground;
    cursor: pointer;

    &:hover {
      box-shadow: inset 0 0 0 10rem @c-primaryLight;
    }
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0.6rem;
      width: 0.8rem;
      height: 1px;
      top: 1.1rem;
      border: 0px solid @c-primaryText;
      background: @c-primaryText;
      transform: rotate(45deg);
      transform-origin: 0.1rem 0.2rem;
      transition: all 0.15s ease-in,
                  background 0s 0.15s linear,
                  border 0s 0.15s linear,
                  width 0.15s 0.15s ease-out,
                  transform 0.15s 0.15s ease-out;
    }
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0.6rem;
      top: 1.1rem;
      width: 0.8rem;
      height: 1px;
      transform-origin: 0.1rem -0.1rem;
      transform: rotate(-45deg);
      background: @c-primaryText;
      transition: all 0.15s 0.15s ease-in;
    }
  }

  &.collapsed {
    flex-basis: @sidebarWidthCollapsed;

    .navigation b {
      opacity: 0;
      transform: translateX(4rem);
    }

    .collapseButton {
      &::before {
        width: 1.1rem;
        height: 0.9rem;
        top: 0.7rem;
        border-width: 1px 0px;
        transform: rotate(0);
        background: transparent;
        transition: all 0.15s ease-in,
                    border-color 0s 0.15s,
                    height 0.15s 0.15s ease-out,
                    top 0.15s 0.15s ease-out;
      }
      &::after {
        width: 1.1rem;
        top: 1.1rem;
        transform: rotate(0);
        transition: all 0.15s ease-in;
      }
    }
  }
}

// Logo
// =============================================================================

.sidebar {
  .logo {
    display: flex;
    height: 6.667rem;
    padding: 1.778rem;
  }
}

.collapsed {
  .cmsLogo {
    --is-collapsed: 1;

    &::after {
      pointer-events: none;
    }
  }
}

// User info
// =============================================================================

.userInfo {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: @f-medium;
  margin-bottom: 42px;
  margin-top: auto;
  padding: 8px 16px;
  position: relative;

  .avatar {
    cursor: inherit;
  }
}

.userName {
  display: inline-block;
  left: @sidebarWidthCollapsed - 1rem;
  max-width: calc(@sidebarWidth - (@sidebarWidthCollapsed - 1rem + 16px));
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  transition-duration: 0.3s, 0.15s;
  transition-property: transform, opacity;
  transition-timing-function: ease-out, linear;
  white-space: nowrap;
}

.collapsed {
  .userName {
    opacity: 0;
    transform: translateX(4rem);
  }
}

// User menu
// =============================================================================

.userMenu {
  @backgroundColor: @c-white;

  opacity: 1;

  [class*="content"] {
    filter: drop-shadow(0 8px 21px rgba(#9ba1b8, 0.25));
    transform: translateX(10px);
  }

  [class*="inner"] {
    background-color: @backgroundColor;
    border: 0;
    border-radius: 4px;
    font-size: 1.222rem;
    font-weight: @f-bold;
    min-width: 14rem;
    padding: 1.444rem 1.778rem;
    position: relative;
    z-index: 1;
  }

  [class*="arrow"] {
    @size: 1rem;

    background-color: @backgroundColor;
    border: 0;
    border-radius: 1px;
    display: inline-block;
    height: @size;
    margin: 0;
    opacity: inherit;
    transform: translate(10%, -50%) scaleX(1.15) rotate(45deg);
    width: @size;
  }

  button {
    align-items: center;
    color: @c-primaryText;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    font-weight: inherit;
    transition: color 0.3s;
    width: 100%;

    & + button {
      margin-top: 1.333rem;
    }

    &:hover {
      color: @c-primary;
    }

    svg {
      height: 1.778rem;
      margin-right: 0.889rem;
    }
  }
}
