// DND list
.scene-editor .dnd-list {
  margin: 0 0 0 3rem;
  padding: 0 0 4rem 0;
  list-style-type: none;
  background-color: @panelBackground;
  font-size: 1.3rem;

  &.dnd-list-empty {
    /* this ensures there is no content jumping during start of dnd */
    height: 9.5rem;
    overflow: hidden;
  }

  .dnd-item {
    border: none;
    background-color: #fcfcfc;
    height: 5.5rem;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    font-weight: @f-medium;
    cursor: grab;
    transition: all 0.1s linear;

    &.canDrop,
    &.canDrop:hover {
      background-color: #fcfcfc;
    }

    &:hover {
      background-color: @c-primaryLight;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: @labelsBackground;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0.3rem;
      height: 100%;
      transform: scaleY(0);
      background-color: @c-primary;
      opacity: 0;
      transition: all 0.1s ease-in;
    }

    &.selected {
      &:after {
        opacity: 1;
        transform: scaleY(1);
        transition: opacity 0s, transform 0.2s ease-out;
      }

      // delete asset
      a {
        opacity: @activeContrastOpacity;
      }
    }
    &.dragging {
      cursor: grabbing;
      opacity: if(
        (@activeContrastOpacity + 0.2 < 1),
        @activeContrastOpacity + 0.2,
        1
      );

      .icon.icon-drag {
        opacity: 1;
      }
    }

    .icon {
      flex: 0 0 auto;
      margin: 0px 1.1rem 0.3rem 0.9rem;
      color: @secondaryColor;

      &.icon-drag {
        margin-right: 0.6rem;
        opacity: @activeContrastOpacity;
      }
    }

    // delete asset
    a {
      opacity: 0.3;
      cursor: pointer;

      &:hover {
        opacity: 1;

        .icon {
          color: @alertColor;
        }
      }
    }

    .action {
      fill: @c-primary;
      flex: 0 0 2.4rem;
      margin-bottom: 0.3rem;
    }

    .title {
      flex: 1 1 100%;
      line-height: 1.7rem;
      max-height: 3.4rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 1.5rem;
    }
    .order {
      position: absolute;
      display: block;
      left: -3rem;
      top: 0;
      bottom: 0;
      font-size: 1.1rem;
      font-weight: @f-medium;
      width: 3rem;
      text-align: center;
      line-height: 5.5rem;
    }
  }

  .dnd-item-empty {
    height: 5.5rem;
    text-align: center;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 3rem;
  }
}
