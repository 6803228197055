// ASSET LIBRARY with search, assets thumbnails and DND zone for upload
.scene-editor .asset-library {
  background-color: @panelBackground;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  order: -1;
  transform: translateX(-1 * 100%);
  transition: transform 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  width: calc(100vw - @panelWidth);
  z-index: 1;

  .tabs {
    flex: none;
  }

  .shared-assets {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    .customFieldsCategorySelector {
      min-width: 120px;
    }

    .assets-body {
      display: flex;
      flex-direction: column;
      flex: 1;

      .filterTags {
        flex: none;
        padding: 1rem 4.5rem;
      }

      .filtersAndSearch {
        padding: 1.5rem 1.5rem 0 4.5rem;

        .filters {
          margin: 0;
          height: auto;
          padding: 0;
        }

        .filterSwitch {
          height: auto;
        }

        .inputWithButton {
          margin-left: 2rem;
          min-width: 20rem;
          flex: 0 1 30rem;
          align-self: flex-start;

          input {
            width: 100%;
            height: 3.4rem;
            outline: none;
            border: 1px solid rgba(0, 0, 0, 0.073);
            transition: border-color 0.15s ease-in-out;
            background-color: #fff;
            color: #0f152e;
            padding: 0 1.25rem;
            margin: 0;
            text-align: left;
            font-size: 1.3rem;
            font-weight: 500;
            border-radius: 1.7rem;
          }
        }
      }

      .assets-content {
        flex: 1;
        padding: 0;
      }
    }
  }

  & > form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  // SEARCH BOX
  .query {
    flex: 0 0 4rem;
    display: flex;

    .button {
      flex: 0 0 4rem;
      height: 100%;
      padding: 0;
      margin: 0;
      background-color: @labelsBackground;
      color: @secondaryColor;
      transition: background 0.2s;

      svg {
        margin: 0;
      }
    }
    .inputBox {
      margin: 0;
      flex: 1 1 auto;
      max-width: none;
    }
    input {
      width: 100%;
      height: 100%;
      border: none;
      text-align: left;
      padding: 0.1rem 1.5rem 0 2.5rem;
      box-sizing: border-box;

      &::placeholder {
        font-size: 1.8rem;
        font-weight: @f-medium;
        color: @headlineColor;
        position: relative;
        text-align: left;
        top: 0.1rem;
      }
    }
  }

  // THUMBNAILS (grid with assets)
  .asset-list-wrapper {
    flex: 1 1 auto;
    overflow: visible;
    outline: none;

    & > div {
      &:first-child {
        animation: enterLibrary 0.4s 0.6s both;
      }
    }

    & > div > div {
      outline: none !important;
      padding: 0.5rem 3rem 3rem 3rem !important;
      box-sizing: content-box !important;
    }

    .assetWrapper {
      padding: 1rem 1.5rem;

      .asset,
      .asset-loading {
        @labelHeight: 6rem;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 0 0.1rem rgba(0, 0, 0, 0.1);
        line-height: @labelHeight - 0.2rem;
        position: relative;
        cursor: grab;
        background-color: #fff;

        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        &.has-alternatives:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #fff;
          left: 0.5rem;
          top: 0.5rem;
          z-index: -1;
          box-shadow: inset 0 0 0 0.1rem rgba(0, 0, 0, 0.1);
        }

        .asset-menu {
          position: absolute;
          right: 0.4rem;
          top: 0.4rem;
          height: 4rem;
          line-height: 4rem;
          .asset-menu-button {
            border-radius: 50%;
            width: 4rem;
            height: 4rem;
            line-height: 4rem;
            text-align: center;
            padding: 0;
            transition: background 0.15s linear;
            cursor: pointer;
            &:hover,
            &:focus {
              background: rgba(0, 0, 0, 0.2);
            }
            &:active {
              transition: background 0.05s linear;
              background: rgba(0, 0, 0, 0.4);
            }
            svg {
              vertical-align: middle;
              margin-top: -0.2rem;
              color: #fff;
            }
          }
        }

        .thumb {
          display: block;
          width: 100%;
          height: calc(100% - @labelHeight);
          overflow: hidden;
          background-color: rgba(0, 0, 0, 0.04);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          box-shadow: inset 0 10rem 10rem -10rem rgba(0, 0, 0, 0.6),
            inset 0 0.1rem 0 0.1rem rgba(0, 0, 0, 0.1);
          position: relative;
          top: -0.1rem;
        }
        .title {
          display: inline-block;
          font-size: 1.3rem;
          line-height: 1.7rem;
          max-height: 3.4rem;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: @f-medium;
          vertical-align: middle;
          padding-left: 4.5rem;
          width: calc(100% - @labelHeight);
          box-sizing: content-box;
        }
        .wall-dimension-status-wrapper {
          @iconHeight: 2rem;
          position: absolute;
          right: 0.8rem;
          bottom: calc((@labelHeight - @iconHeight) / 2);
          height: @iconHeight;

          & + .title {
            width: calc(100% - 9rem);
          }
        }
        .type {
          position: absolute;
          left: 0.7rem;
          top: 0.5rem;
          color: #fff;
        }
        .drag {
          position: absolute;
          left: 0.1rem;
          bottom: 0.1rem;
          height: 6rem;
          padding: 0 1rem 0 1.2rem;
          color: #000;
          box-sizing: content-box;
        }

        &.dragging {
          cursor: grabbing;
          box-shadow: inset 0 0 0 0.1rem @primaryColor,
            0 0 1rem rgba(0, 0, 0, 0.1);

          .thumb {
            box-shadow: inset 0 10rem 10rem -10rem rgba(0, 0, 0, 0.2),
              inset 0 0.1rem 0 0.1rem @primaryColor;
          }
          .drag {
            background-color: @primaryColor;
          }
          .icon-drag {
            color: #fff;
          }
        }

        &.no-preview {
          .thumb {
            box-shadow: none;
            background-image: none !important;
          }
          .type {
            color: rgba(0, 0, 0, 0.1);
            width: 6.4rem !important;
            height: 6.4rem !important;
            font-size: 6.4rem !important;
            right: calc(50% - 3.2rem);
            left: unset;
            top: calc(50% - 3.2rem - @labelHeight / 2);
          }
        }
      }
      .asset-loading {
        font-size: 1.3rem;
        font-weight: @f-bold;
        color: #aaa;
        text-transform: uppercase;
        text-align: center;
        line-height: 20rem;
      }
    }

    .asset-list-empty,
    .asset-list-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      min-width: 14rem;
      padding: 2rem 1rem;
      text-align: center;
      margin: -3rem 0 0 -8rem;
      font-size: 1.4rem;
      font-weight: @f-bold;
      color: #aaa;
      text-transform: uppercase;
    }
    .asset-list-loading {
      border-radius: 1rem;
    }
  }

  // UPLOAD files with DND zone and progressbar
  .upload-wrapper {
    @uploadButtonWidth: 33rem;

    display: flex;

    .upload {
      flex: 1 1 auto;
      padding: 2.5rem;
      width: 100% !important;
      margin: 0;
      text-align: center;
      font-size: 1.4rem;
      font-weight: @f-bold;
      color: rgba(0, 0, 0, 0.4);
      background-color: @labelsBackground;
      transition: all 0.2s;
      position: relative;

      b {
        font-weight: bold;
      }
      &.error {
        color: rgba(255, 0, 0, 0.5);

        .icon-add {
          display: none;
        }
      }
      &:hover,
      &.drop {
        color: rgba(0, 0, 0, 0.7);
        background-color: lighten(@c-success, 15%);
      }
      svg {
        margin: -0.2rem 1.2rem 0 0;
        vertical-align: middle;
      }
      .bar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1rem;
        background-color: @c-success;
        transition: width 1s;
      }
    }

    .add-url-button {
      flex: 0 1 auto;
      width: @uploadButtonWidth;
      padding: 2.5rem;
      max-width: 50%;
      color: rgba(0, 0, 0, 0.5);
      background: shade(@labelsBackground, 5%);
      font-weight: @f-bold;
      transition: all 0.2s;

      &:hover {
        color: rgba(0, 0, 0, 0.7);
        background-color: lighten(@c-success, 10%);
      }

      svg {
        margin: -0.6rem 0.7rem 0 0;
        vertical-align: middle;
      }
    }
  }
}

@keyframes enterLibrary {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  50% {
    visibility: visible;
  }
  100% {
    opacity: 1;
  }
}

#modal {
  @modalWidth: 40rem;
  @modalPadding: 1.5rem;
  @inputHeight: 4.4rem;
  @labelWidth: 7rem;

  .modal-upload {
    width: @modalWidth;
    max-width: 90vw;
    min-height: 20rem;
    max-height: 90vh;
    overflow: auto;
    background: #fff;
    box-shadow: 0.3rem 0 5rem rgba(0, 0, 0, 0.4);

    .thumb {
      width: 100%;
      height: 30rem;
      line-height: 30rem;
      background: @labelsBackground;
      position: relative;
      text-align: center;

      .icon {
        opacity: 0.2;
        vertical-align: middle;
      }
      img {
        vertical-align: middle;
        max-width: 100%;
        max-height: 100%;
      }
      .preload {
        opacity: 0;
      }
      img.loading ~ .preload {
        opacity: 1;
      }
    }

    .modalUploadForm {
      padding: 1.5rem 2rem 2rem 2rem;

      .modalButtons {
        text-align: right;

        .buttonAlert {
          float: left;
        }
      }
      .assets-count {
        float: left;
        margin: 1rem 2rem 0 0.3rem;
        font-size: 1.2rem;
      }
    }
  }
}
