.page-dashboard {
  padding: 2rem;

  .pageHeader {
    align-items: center;
    flex-flow: row wrap;
    gap: 2rem;
    max-width: calc(4 * 28rem + 3 * 2rem);

    &.has-alert {
      align-items: flex-start;
    }
  }

  .pageTitle[class] {
    flex: 1;
    margin: 0;
    white-space: nowrap;
  }
  .actionButtons {
    flex: 1;
    text-align: right;
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  .card {
    flex: 0 0 28rem;
    background-color: @c-white;
    border: 1px solid @c-pearl;
    border-radius: 0.8rem;
    box-sizing: border-box;
    padding: 2rem;

    .buttonWrapper {
      text-align: center;
      margin-top: 8rem;
    }

    .title {
      font-weight: @f-normal;
      margin-bottom: 2rem;
    }

    .avatarBox {
      display: flex;
      margin-bottom: 2rem;

      .avatar {
        flex: 1 0 8rem;
        height: 8rem;
        border-radius: 50%;
      }

      .avatarPlaceholder {
        display: flex;
        font-size: 2.5rem;

        span {
          align-self: center;
          width: 100%;
        }
      }

      .info {
        flex-basis: 100%;
        margin-top: 1.5rem;
        margin-left: 1.5rem;
        align-self: center;

        .name {
          font-weight: @f-bold;
          margin-bottom: 0.5rem;
        }

        .role {
          color: @c-denimLight;
        }
      }
    }

    .itemList {
      display: flex;
      flex-wrap: wrap;

      .item {
        margin-top: 1.7rem;
        flex: 1;
        margin-right: 1rem;

        .label {
          color: @c-denimLight;
          text-align: left;
          display: block;
          margin-bottom: 0.5rem;
          font-size: 1.3rem;
          padding: 0;
          font-weight: @f-normal;
          line-height: 1;
        }

        &:first-child {
          flex: 100%;
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
          margin-right: 0;
        }
      }

      &.stats {
        padding: 0;
        margin: 0;

        .item {
          margin: 0;
          padding: 0.8rem;
          list-style: none;
          flex: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          margin-bottom: -1px;

          &.itemClickable:hover {
            background-color: @c-primaryLight;
            cursor: pointer;
          }

          &:last-child {
            border-bottom: 0;
          }

          .label {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
