.background-editor {
  background: rgba(@c-black, 0.7);
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  width: 100vw;

  &__head {
    background: @c-white;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 40px;
    padding: 0.444rem;
    right: 40px;
    top: 25px;
    z-index: 1;

    form,
    & > div {
      align-items: center;
      display: flex;
    }
    form {
      padding-left: 0.899rem;
      padding-right: 1.778rem;
    }

    a {
      color: @c-denimLight;
      text-decoration: underline;

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__body {
    flex: 1;
    position: relative;
  }
}

.background-editor {
  .number[class][class] {
    --size: 4rem;

    margin: 0;
    margin-left: 0.889rem;
  }
  .number__body {
    border-radius: 4px;
  }

  .button {
    border-radius: 4px;
    height: 4rem;
    line-height: normal;
    padding-left: 1.333rem;
    padding-right: 1.333rem;
    vertical-align: initial;

    & + .button {
      margin-left: 0.889rem;
    }

    svg {
      height: 1.778rem;
      margin: 0;
      margin-right: 0.889rem;
      width: 1.778rem;
    }

    &.--toggle {
      --background: transparent;
      --border: @c-primary;
      --color: @c-primary;

      background-color: var(--background);
      border-color: var(--border);
      color: var(--color);
      display: grid;
      padding: 0;
      place-items: center;
      width: calc(2 * 1.333rem + 1em);

      svg {
        margin: 0;
        width: 16px;
      }

      &.is-active {
        --background: @c-error;
        --border: @c-error;
        --color: @c-white;
      }
    }
  }

  [data-guide] {
    --size: 1px;

    background: @c-error;
    pointer-events: none;
    position: absolute;
    user-select: none;

    &[data-guide="horizontal"] {
      height: var(--size);
    }
    &[data-guide="vertical"] {
      width: var(--size);
    }
  }
}

.background-editor {
  .alert {
    --color: currentColor;

    align-items: center;
    animation: alert 0.3s @ease-out-circ both;
    background: @c-white;
    border-radius: 2px;
    color: #9f9f9f;
    display: inline-flex;
    font-size: 1.333rem;
    font-weight: @f-medium;
    left: 50%;
    overflow: hidden;
    padding: 1.111rem 2.222rem;
    position: absolute;
    transform: translate(-50%, 0);
    width: ~"min(calc(100vw - 2 * 40px), 60rem)";

    &::before {
      background: var(--color);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 5px;
    }

    [class*="ico"] {
      color: var(--color);
      flex-shrink: 0;
      height: 24px;
      margin-right: 12px;
      width: 24px;
    }

    &.--warning {
      --color: #f79824;
    }

    &__body {
      :first-child {
        margin-top: 0;
      }
      :last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      color: @c-black;
      font-weight: @f-bold;
    }
  }
}

@keyframes alert {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.background-scheme {
  --radius: 2px;
  --size: 1px;

  background: @c-white;
  border-radius: calc(0.5 * var(--radius));
  pointer-events: none;
  position: absolute;
  user-select: none;

  &::after {
    border: var(--size) solid var(--color, transparent);
    border-radius: var(--radius);
    content: "";
    position: absolute;
    bottom: calc(-1 * var(--size));
    left: calc(-1 * var(--size));
    right: calc(-1 * var(--size));
    top: calc(-1 * var(--size));
    z-index: 1;
  }

  &.--danger {
    --color: @c-error;
  }
  &.--success {
    --color: @c-success;
  }
  &.--warning {
    --color: @c-warning;
  }
}

.background-wall {
  color: rgba(@c-black, 0.3);
  display: grid;
  font-size: 3.667rem;
  font-weight: @f-medium;
  place-items: center;
  pointer-events: none;
  position: absolute;
  user-select: none;

  & + & {
    border-left: 1px solid currentColor;
  }
}

.background-asset {
  --color: #00d1ff;
  --corner-size: 1.111rem;
  --edge-size: 1px;
  --offset: -6px;

  border: var(--edge-size) solid var(--color);
  position: absolute;

  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    user-select: none;
    width: 100%;
  }

  &__handle {
    background-color: var(--background, @c-white);
    border-radius: var(--radius, 0);
    display: block;
    height: var(--height, auto);
    position: absolute;
    transform: translate(var(--dx, 0), var(--dy, 0));
    width: var(--width, auto);
    z-index: 1;

    &::before {
      bottom: var(--offset);
      content: "";
      display: block;
      left: var(--offset);
      position: absolute;
      right: var(--offset);
      top: var(--offset);
    }

    &[data-corner] {
      --height: var(--corner-size);
      --radius: 50%;
      --width: var(--corner-size);
      border: inherit;
    }
    &[data-corner*="bottom"] {
      bottom: 0;
    }
    &[data-corner*="left"] {
      left: 0;
    }
    &[data-corner*="right"] {
      right: 0;
    }
    &[data-corner*="top"] {
      top: 0;
    }
    &[data-corner="left bottom"] {
      --dx: -50%;
      --dy: 50%;
      cursor: nesw-resize;
    }
    &[data-corner="right bottom"] {
      --dx: 50%;
      --dy: 50%;
      cursor: nwse-resize;
    }
    &[data-corner="left top"] {
      --dx: -50%;
      --dy: -50%;
      cursor: nwse-resize;
    }
    &[data-corner="right top"] {
      --dx: 50%;
      --dy: -50%;
      cursor: nesw-resize;
    }

    &[data-edge] {
      --background: var(--color);
    }
    &[data-edge="bottom"],
    &[data-edge="top"] {
      --height: var(--edge-size);
      cursor: ns-resize;
      left: 0;
      right: 0;
    }
    &[data-edge="left"],
    &[data-edge="right"] {
      --width: var(--edge-size);
      cursor: ew-resize;
      bottom: 0;
      top: 0;
    }
    &[data-edge="bottom"] {
      --dy: var(--edge-size);
      bottom: 0;
    }
    &[data-edge="left"] {
      --dx: calc(-1 * var(--edge-size));
      left: 0;
    }
    &[data-edge="top"] {
      --dy: calc(-1 * var(--edge-size));
      top: 0;
    }
    &[data-edge="right"] {
      --dx: var(--edge-size);
      right: 0;
    }
  }
}
