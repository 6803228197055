// global styles
@import "../cms/fonts.less";
@import "../cms/tooltip.less";
@import "../cms/loading-indicators.less";
@import "../cms/filters.less";
@import "../cms/icons.less";
@import "../cms/typography.less";
@import "../cms/layout.less";
@import "../cms/flash-message.less";
@import "../cms/message.less";
@import "../cms/header.less";
@import "../cms/avatar.less";
@import "../cms/sidebar.less";
@import "../cms/button.less";
@import "../cms/inputs.less";
@import "../cms/combobox.less";
@import "../cms/toggle.less";
@import "../cms/tabs.less";
@import "../cms/grid.less";
@import "../cms/badge.less";
@import "../cms/modal.less";
@import "../cms/modal-collaborate.less";
@import "../cms/preview-media-modal.less";
@import "../cms/wall-dimension-status.less";
@import "../cms/logo.less";
@import "../cms/breadcrumb.less";
@import "../cms/galleryList.less";
@import "../cms/category-selector.less";
@import "../cms/hint.less";
@import "../cms/toast.less";
@import "../cms/switch.less";
@import "../cms/number.less";

// pages styles
@import "../cms/pages/assets.less";
@import "../cms/pages/component-page.less";
@import "../cms/pages/custom-fields.less";
@import "../cms/pages/dashboard.less";
@import "../cms/pages/login.less";
@import "../cms/pages/presentations.less";
@import "../cms/pages/scenes.less";
@import "../cms/pages/settings.less";
@import "../cms/pages/triggers.less";
@import "../cms/pages/user.less";
@import "../cms/pages/users.less";

// scene editor styles
@import "../scene-editor/forms.less";
@import "../scene-editor/layout.less";
@import "../scene-editor/content.less";
@import "../scene-editor/left-panel.less";
@import "../scene-editor/main-panel.less";
@import "../scene-editor/dnd-list.less";
@import "../scene-editor/asset-library.less";
@import "../scene-editor/scene.less";
@import "../scene-editor/background.less";

// CMS
@baseRem: 10px;
@base: 1rem;

@f-family: "Inter UI", Nunito, "Open Sans", Roboto, Arial, sans-serif;
@f-normal: 400;
@f-medium: 500;
@f-bold: 700;
// @f-extrabold: 800;
@f-size-base: @base;

@c-denim: #0f152e;
@c-denimLight: #878a98;
@c-cloud: #c7c7c7;
@c-blueDodger: #3370ff;
@c-silver: #d0d0d0;
@c-pearl: #eee;
@c-white: #fff;
@c-black: #000;
@c-primary: #004bff;
@c-primaryContrast: #fff;
@c-primaryLight: rgba(0, 75, 255, 0.05);
@c-secondary: #27d388;
@c-secondaryContrast: #fff;
@c-secondaryDark: #00ce9a;
@c-success: #27d388;
@c-successContrast: #fff;
@c-warning: #ff9100;
@c-warningContrast: #fff;
@c-error: #fc4444;
@c-errorContrast: #fff;
@c-info: #00a4e2;
@c-infoContrast: #fff;
@c-primaryText: @c-denim;
@c-secondaryText: @c-denimLight;
@c-bodyBackground: #f8f8f8;
@c-contentBackground: #fff;
@c-contentBorder: @c-pearl;
@c-avatarBackground: @c-secondaryDark;
@c-avatarText: @c-secondaryContrast;
@c-logoPrimary: #080a1b;
@c-logoSecondary: rgba(116, 248, 180, 0.76);
@c-scrollbarThumb: @c-cloud;
@c-scrollbarBackground: #fafafa;

@z-sidebar: 300;
@z-header: 200;
@z-page: 100;

@headerHeight: 5rem;
@sidebarWidth: 19rem;
@sidebarWidthCollapsed: 7rem;

@boxShadowLighten: inset 0 0 0 100vmax rgba(255, 255, 255, 0.2);
@boxShadowTooltip: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15);

@input-height: 3.4rem;
@input-border-radius: 0.3rem;
@input-rounded-border-radius: 1.7rem;
@input-margin: 1.3rem;
@input-c-text: @c-denim;
@input-c-background: @c-white;
@input-c-border: rgba(0, 0, 0, 0.073);
@input-c-border-active: @c-primary;

// SCENE EDITOR
@primaryColor: @c-primary; // topbar color
@primaryContrastColor: @c-primaryContrast;
@secondaryColor: #3b3c46;
@secondaryContrastColor: #fff;
@activeColor: @c-secondary; // active item in menu
@activeContrastColor: @c-secondaryContrast;
@activeContrastOpacity: 0.3;

@successColor: #4bc525;
@successContrastColor: #fff;
@warningColor: #fb0;
@warningContrastColor: #fff;
@alertColor: #f91644;
@alertContrastColor: #fff;

@headlineColor: #a9a9b5;
@textColor: #151730;
@sceneBackground: #20232a;
@panelBackground: #f8f8f8;
@labelsBackground: #ebebeb;
@boundingBoxColor: #43b0ff;

@inputsBackground: #ebebeb;
@inputsColor: #111;
@inputFocusColor: lighten(@primaryColor, 15%);
@inputErrorColor: lighten(@alertColor, 30%);
@labelColor: #666;

@panelWidth: 44.444rem;

// @link http://matthewlein.com/ceaser/
@ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);

@iconCaretDown: "data:image/svg+xml,%3Csvg width='8' height='4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 .768L3.768 3 6.536.768' stroke='%230f152e' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E";

// =============================================================================
// ¯\(°_o)/¯ HAMMER TIME! ¯\(°_o)/¯
// =============================================================================

.appVersion {
  border-top: 1px solid @c-pearl;
  color: @c-secondaryText;
  display: block;
  font-size: 1.222rem;
  margin-top: 8rem;
  padding-bottom: 4rem;
  padding-top: 1.5rem;
}
