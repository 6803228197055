.previewMediaModal {
  width: auto;
  max-width: 96vw;
  max-height: 96vw;

  .modalContent {
    overflow: hidden;
    text-align: center;
    background: @c-pearl;
    padding: 0;

    img,
    video,
    audio {
      max-width: 96vw;
      max-height: calc(90vh - 6rem);
      vertical-align: top;
      align-self: center;
      outline: none;
    }
    audio {
      min-width: 40rem;
    }
  }

  .mediaStatus {
    display: inline-block;
    padding: 0 0.7rem;
    margin: 0 0.5rem 0 1rem;
    font-size: 1rem;
    line-height: 1.7rem;
    position: relative;
    top: -1px;
    font-style: normal;
    font-weight: @f-medium;
    text-transform: uppercase;
    background: @c-info;
    color: @c-secondaryContrast;
    border-radius: @input-border-radius;

    &.mediaStatus-status_ready {
      background: @c-secondaryDark;
      display: none;
    }
    
    &.mediaStatus-status_error {
      background: @c-error;
    }
  }

  .backgroundLink {
    display: block;
    background: #111;

    .backgroundImage {
      max-height: calc(90vh - 25rem);
    }
  }
  
  .tabs {
    .tabHeader {
      padding: 0;

      .tabNav {
        overflow-x: auto;

        &::-webkit-scrollbar {
          height: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border: none;
        }
        &::-webkit-scrollbar-track {
          background-color: #e1e1e1;
        }

        .item {
          padding: 1rem 3rem 1rem;
          min-width: 10rem;
        }
      }
    }
    
    .buttonDelete {
      position: absolute;
      right: 2rem;
      top: 7rem;

      svg {
        pointer-events: none;
      }
    }

    .fileInfo {
      margin: 1.5rem 7rem 1.5rem 2rem;
    }

    .wall-room-compatibility {
      display: inline;
      padding-left: 2rem;

      .room {
        display: inline-block;
        padding: 0.7rem 1rem;

        .wall-dimension-status {
          padding-right: 0.5rem;
        }
      }
    }
  }
}
