:root {
  --baseFontSize: @baseRem;

  font-size: var(--baseFontSize);

  @media (max-width: 1200px) {
    --baseFontSize: calc(0.9 * @baseRem);
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: @f-family;
  font-size: 1.4rem;
  line-height: 1.5;
  background-color: @c-bodyBackground;
  color: @c-primaryText;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

a {
  cursor: pointer;
}

p {
  margin: 0 0 1rem;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid #eee;
  height: 0;
  box-sizing: content-box;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

label {
  display: inline-block;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
}

.application {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.page {
  overflow: auto;
  height: 100%;
  position: relative;

  .pageHeader {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    .pageTitle {
      flex: 1 1 50%;
      margin: 0;
      margin-bottom: 1.5rem;
      word-break: break-word;
    }

    .search {
      flex: 1 1 0;
      min-width: 300px;
      margin: auto 30px;
    }

    .buttons {
      display: flex;
      flex: 1 1 50%;
      justify-content: flex-end;
    }

    .pageBreadcrumbs {
      font-size: 1.5rem;
      font-weight: @f-normal;
      margin-right: 1.1rem;
      color: @c-secondaryText;

      button {
        color: @c-secondaryText;
        display: inline-block;
        margin-right: 1rem;
        padding: 0;

        &:hover {
          color: @c-primaryText;
          text-decoration: none;
        }
      }
    }

    @media (min-width: 1300px) {
      align-items: center;
      flex-direction: row;

      .pageTitle {
        margin-bottom: 0;
      }
    }
  }
}

.hidden,
.displayNone {
  display: none !important;
}

// custom scrollbars
::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-track {
  background-color: @c-scrollbarBackground;
}

::-webkit-scrollbar-thumb {
  width: 0.7rem;
  border-radius: 1rem;
  min-height: 3rem;
  background-color: @c-scrollbarThumb;
  border: 0.3rem solid @c-scrollbarBackground;
}
