.icon {
  width: 1.6rem;
  height: 1.6rem;
  font-size: 1.6rem;
  line-height: 1;
  stroke-width: 0;
  display: inline-block;
  stroke: currentColor;
  fill: currentColor;
  text-align: center;
  overflow: hidden;
  vertical-align: top;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  pointer-events: none;
}
.icon20 {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}
.icon24 {
  width: 2.4rem;
  height: 2.4rem;
  font-size: 2.4rem;
}
.icon32 {
  width: 3.2rem;
  height: 3.2rem;
  font-size: 3.2rem;
}
.icon128 {
  width: 12.8rem;
  height: 12.8rem;
  font-size: 12.8rem;
}
.icon192 {
  width: 19.2rem;
  height: 19.2rem;
  font-size: 19.2rem;
}
