/**
 * Page User profile.
 */

// =============================================================================
// User profile page
// =============================================================================

.page-user-profile {
  display: flex;
  flex-direction: column;

  .pageHeader {
    align-items: center;
    border-bottom: 1px solid @c-primary;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 6.667rem;
    margin-bottom: 0;
    padding: 0 3rem;
  }

  .pageHeading {
    font-size: 2rem;
    font-weight: @f-normal;
    margin: 0;
  }

  .pageContent {
    padding: 3rem;
  }
}

// =============================================================================
// ¯\(°_o)/¯ HAMMER TIME! ¯\(°_o)/¯
// =============================================================================

.editProfile {
  width: 36rem;

  label,
  .label {
    color: @c-denimLight;
  }

  .item {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.2rem;

    .label {
      text-align: left;
      padding-left: 0;
      font-size: 1.4rem;
    }
  }

  .contactBox {
    display: flex;
    flex-direction: row;
    margin-right: 1.3rem;

    .inputBox {
      flex: 0 0 50%;

      &:first-child {
        margin-right: 2rem;
        flex: 0 0 calc(50% - 2rem);
      }
    }
  }

  .photoBox {
    display: flex;
    flex-direction: row;
    align-self: baseline;
    margin-bottom: 1.7rem;

    .item {
      justify-content: center;
      margin-left: 1.5rem;
      margin-bottom: 0;
      font-weight: @f-bold;
      font-size: 1.3rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .avatarDropZone {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    border-style: dashed;
    border-width: 2px;
    border-color: @c-denimLight;
    min-width: 8rem;
    min-height: 8rem;
    box-sizing: content-box;
    cursor: pointer;
    position: relative;
    outline: none;

    &.empty {
      border-radius: 0;

      img {
        opacity: 0;
      }
    }

    .avatarDropZoneLabel {
      text-align: center;
      padding: 2rem;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 8rem;
      height: 8rem;
      object-fit: cover;
      border: none;
      outline: none;
    }
  }
}
