h1, h2, h3, h4, h5, h6 {
  font-weight: @f-medium;
  margin: 1em 0 0.5em;
  line-height: 1.1;

  &:first-child {
    margin-top: 0.5em;
  }

  &.wideMargin {
    margin: 1.8em 0 1em;
  }
}

h1 {
 font-size: 2rem; 
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.7rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.4rem;
  font-weight: @f-bold;
}

h6 {
  font-size: 1.2rem;
  font-weight: @f-bold;
}
