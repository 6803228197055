.galleryList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem,1fr));
  row-gap: 2rem;
  column-gap: 2rem;
  padding-bottom: 4rem;

  .item {
    position: relative;
    width: 100%;
    height: 18rem;
    background-color: @c-white;
    display: flex;
    border-radius: 2px;
    border: 1px solid @c-pearl;
    cursor: grab;

    &.dndIsDragging {
      outline: 2px dashed @c-primary;
    }
    
    &.dndIsOver:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
      background: @c-primary;
      opacity: 0.5;
    }

    .aside {
      width: 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      button {
        padding: 0 0 0.5rem;
        margin: 0;
        border: none;
        background: none;
        width: 100%;
        text-align: center;
        height: 3rem;
        color: @c-black;
        opacity: 0.6;
        transition: all 0.1s linear;

        &:hover,
        &:focus {
          opacity: 1;
          color: @c-primary;
        }

        &.buttonRemove:hover,
        &.buttonRemove:focus {
          color: @c-error;
        }

        svg {
          height: 24px;
          vertical-align: middle;
          fill: currentColor;
          pointer-events: none;
        }
      }

      .buttonDrag {
        margin-bottom: auto;
        padding-top: 0.7rem;
        cursor: grab;

        svg {
          height: 16px;
        }
      }
    }

    .bg {
      height: 100%;
      flex: 1 0 0;
      background-size: cover;
      background-position: center;
      position: relative;
      cursor: pointer;

      &:before {
        content: '';
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }


      .title {
        color: @c-white;
        font-size: 1.6rem;
        padding: 2rem;
        width: 100%;
        position: absolute;
        bottom: 0;
        text-orientation: upright;
      }
    }

    &.addMore {
      background-color: transparent;
      position: relative;
      border: none;
      cursor: initial;

      &::before {
        content: "";
        display: inline-block;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        border: 2px solid @c-cloud;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: border 0.1s linear;
      }

      .buttonDialogBox {
        width: 17rem;
      }

      .buttonPlus {
        width: 100%;
        height: 100%;
        border-radius: 0;
        border-color: @c-cloud;
        &::before,
        &::after {
          background: @c-cloud;
        }
      }
      
      &:hover {
        .buttonPlus,
        &::before {
          border-color: @c-primary;
        }
        .buttonPlus::before,
        .buttonPlus::after {
          background: @c-primary;
        }
      }
    }

   .buttonDialogBox {
      left: calc(50% + 3.5rem);
    }
  }
}
