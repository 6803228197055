.ReactVirtualized__Grid {
  outline: none;
  padding: 0 0 2rem 0;
  overflow-y: scroll !important;
}

.gridRow {
  border: 1px solid @c-contentBorder;
  border-radius: @input-border-radius;
  padding: 0.5rem;
  margin: 0 0.7rem 0 2rem;
  display: flex;
  align-items: center;
  height: 6rem;
  overflow: hidden;
  color: @c-secondaryText;
  position: relative;
  background: @c-white;

  hr {
    flex: 0 0 1px;
    width: 1px;
    height: 3rem;
    background: @c-contentBorder;
  }

  .gridCell {
    flex: 2 1 0;
    padding: 0 2rem;
    position: relative;
    max-height: 100%;
    font-size: 1.2rem;

    &,
    h3,
    h4,
    .gridRowTitle {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:first-of-type {
      flex: 5 1 0;
    }

    &:last-of-type {
      flex: 0.1 0 0;
      white-space: nowrap;
      text-align: right;
      overflow: visible;
    }
  }

  .gridCellStatus {
    flex: 0 0 6rem;
  }

  .gridImage {
    display: inline-block;
    height: 100%;
    width: 12rem;
    background: rgba(0, 0, 0, 0.08);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 0.5rem;
    position: relative;
    outline: none;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -55%);
      width: 24px;
      height: 24px;
      opacity: 0.3;
      fill: @c-denim;
    }

    small {
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgba(100, 100, 100, 0.7);
      color: @c-white;
      font-style: normal;
      font-size: 1rem;
      line-height: 1.55;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      padding: 0 1rem;
    }

    .previewIcon {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      opacity: 0;
      transition: opacity 0.2s linear;

      svg {
        fill: @c-white;
        opacity: 1;
      }
    }
    &:hover .previewIcon {
      opacity: 1;
    }
  }

  button svg {
    height: 24px;
  }
  .gridRowTitle {
    margin: 0;
    padding: 0.2rem 0 0.4rem 0;
    color: @c-primaryText;
    max-width: 100%;
  }
  h3 {
    font-size: 1.6rem;
  }
  h4 {
    margin: 0;
    padding: 0.3rem 0 0.2rem 0;
    color: @c-primaryText;
    font-size: 1.4rem;
  }
  em {
    display: inline-block;
    padding: 0 0.7rem;
    margin-right: 0.7rem;
    font-size: 1rem;
    line-height: 1.7rem;
    position: relative;
    top: -1px;
    font-style: normal;
    font-weight: @f-medium;
    text-transform: uppercase;
    background: @c-info;
    color: @c-secondaryContrast;
    border-radius: @input-border-radius;

    &.success {
      background: @c-secondaryDark;
      display: none;
    }

    &.error {
      background: @c-error;
    }

    & + em {
      margin-left: -0.3rem;
    }
  }

  .gridRowProgressbar {
    position: absolute;
    left: -100%;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: @c-secondaryDark;
    transition: transform 0.5s;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: -300px;
      top: 0;
      width: 300px;
      height: 100%;
      background: linear-gradient(
          90deg,
          transparent 0%,
          rgba(255, 255, 255, 0.7) 50%,
          transparent 100%
        )
        no-repeat;
      animation: movebar 5s linear infinite;
    }
  }
}

.gridRowPlaceholder {
  border: 1px dashed darken(@c-pearl, 5%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  width: -webkit-fill-available;
  width: -moz-available;
  color: @c-denimLight;
  transition: all 0.15s;

  svg {
    margin: -1px 6px 0 0;
    fill: currentColor;
  }

  button&:hover {
    border-color: @c-cloud;
    color: @c-denim;
  }

  &.dndIsOver {
    color: @c-primary;
    border-color: @c-primary;
    box-shadow: inset 0 0 0 100pxs @c-primaryLight;
  }
}

.gridRowForm {
  justify-content: flex-start;
  color: @c-primaryText;
}

.gridRowDnD {
  padding-bottom: 1px;
  margin-bottom: -1px;
  position: relative;

  &.dndIsOver::before {
    content: "";
    display: block;
    height: 2px;
    background: @c-primary;
    position: absolute;
    top: -6px;
    left: 1rem;
    right: 1rem;
  }

  &.dndIsDragging ~ .dndIsOver::before {
    bottom: 5px;
    top: auto;
  }

  .gridDrag {
    flex: 0 0 2.6rem;
    height: 100%;
    padding: 0.5rem;
    margin: -0.4rem -2rem 0 0;
    box-sizing: content-box;
    align-self: flex-start;
    position: relative;
    z-index: 10;

    // disable buttons ripple effect to prevent weird looking drag preview
    &:hover ~ * .button::after {
      width: 0;
      height: 0;
      transform: scale(0, 0);
    }

    i {
      cursor: grab;
      display: inline-block;
      padding: 0.5rem;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.gridPlaceholder {
  display: block;
  text-align: center;
  color: @c-secondaryText;
  line-height: 8rem;
  white-space: nowrap;
  padding: 0 3rem;
}

.gridItemPlaceholder {
  height: 6rem;
  margin: 0 2rem;
  display: block;
  border-radius: @input-border-radius;
  background: transparent;
  opacity: 0;
  animation: fadeInOut 3s infinite;
}

.ReactVirtualized__Grid__innerScrollContainer > div {
  .loop(@counter) when (@counter > 0) {
    &:nth-child(@{counter}) .gridItemPlaceholder {
      background: linear-gradient(
        -90deg,
        rgba(253, 253, 253, (1.1 - @counter / 10)) 0%,
        rgba(255, 255, 255, (1.1 - @counter / 10)) 100%
      );
    }
    .loop((@counter - 1));
  }
  .loop(10);
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

[draggable] {
  cursor: grab;
}

.isDragging {
  cursor: grabbing;
}
