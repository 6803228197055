fieldset {
  border: none;
}

.input,
.textarea,
.select,
div.ReactPasswordStrength .input {
  width: 100%;
  height: @input-height;
  outline: none;
  border: 1px solid @input-c-border;
  border-radius: @input-border-radius;
  transition: border-color 0.15s ease-in-out;
  background-color: @input-c-background;
  color: @input-c-text;
  padding: 0 1.25rem;
  margin: 0;
  text-align: left;
  font-size: 1.3rem;
  font-weight: @f-medium;

  &::placeholder {
    text-align: center;
    color: @input-c-text;
    opacity: 0.5;
    transition: opacity 0.15s ease-in-out;
  }

  &:hover,
  .inputWithButton:hover & {
    border-color: @input-c-border-active;
  }

  &:focus {
    border-color: @input-c-border-active;
    &::placeholder {
      opacity: 0;
    }
  }

  &:not([value='']) + .inputButton {
    background: @input-c-border-active;
    color: @c-white;
  }

  &[readonly] {
    border-color: @input-c-border !important;
    cursor: default !important;
  }

  &[disabled] {
    border-color: @input-c-border !important;
    color: @c-cloud !important;
    cursor: default !important;
  }
}

.textarea {
  resize: vertical;
  height: 8rem;
  min-height: @input-height;
  max-height: 40vh;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.select {
  appearance: none;
  padding-right: @input-height + 1.25rem;
  background-color: @c-white;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMT\
    IgNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSIjZmZ\
    mZmZmIiBkPSJNMC4yNSwxLjUzbDUuMTQsNS4xNGMwLjM0LDAuMzQsMC44OCwwLjM0LDEuMjEs\
    MGw1LjE0LTUuMTRjMC4zNC0wLjM0LDAuMzQtMC44OCwwLTEuMjFzLTAuODgtMC4zNC0xLjIxL\
    DBMNiw0Ljg2TDEuNDYsMC4zMmMtMC4zNC0wLjM0LTAuODgtMC4zNC0xLjIxLDBTLTAuMDgsMS\
    4yLDAuMjUsMS41M0wwLjI1LDEuNTN6Ii8+PC9zdmc+'),
    linear-gradient(-90deg, @c-primary @input-height, #fff @input-height);
  background-position: right 1.1rem center, right top;
  background-size: 1.2rem 0.7rem, 100% 100%;
  background-origin: border-box;
  background-repeat: no-repeat;
  cursor: pointer;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: @c-primaryContrast;
  }
  &::-webkit-scrollbar-track {
    background-color: @c-primary;
  }

  option {
    padding: 0.6rem 1.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &[multiple],
  &[size] {
    height: auto;
    overflow: auto;
    padding-left: 0;
    padding-right: @input-height - 0.1rem;
  }
}

.inputRounded,
.inputWithButton .input {
  border-radius: @input-rounded-border-radius;
}

.inputWithButton {
  @buttonSize: @input-height;
  position: relative;

  .input {
    padding-right: @buttonSize + 1rem;
  }

  .button {
    position: absolute;
    width: (@buttonSize);
    height: (@buttonSize);
    right: 0;
    top: 0;
    padding: 0;
    border: none;
    border-radius: 50%;
    background: @c-pearl;
    color: @c-denimLight;

    svg {
      fill: currentColor;
      height: 24px;
      vertical-align: middle;
      margin: 0;
    }
  }

  .button:hover,
  &:hover .button,
  .input:focus ~ .button {
    background: @input-c-border-active;
    color: @c-white;
  }
}

.inputBox {
  max-width: 35rem;
  margin: @input-margin 0;

  .input {
    margin: 0;
  }

  label {
    margin: 0 0 0.2rem;
    color: @c-secondaryText;
    font-size: 1.3rem;
    font-weight: @f-medium;
    word-break: break-all;
    
    small {
      display: block;
      color: @c-primaryText;
      font-size: 1.1rem;
      font-weight: @f-medium;
      padding-bottom: 0.2rem;
    }
  }

  & > strong {
    display: block;
    background: @c-secondary;
    color: @c-secondaryContrast;
    font-size: 1.2rem;
    font-weight: @f-medium;
    padding: 0.6rem 1.5rem 0.5rem 1.5rem;
    margin-top: 0.5rem;
    animation: slideDown 0.2s cubic-bezier(1, 0, 1, 0);
  }

  @keyframes slideDown {
    from {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      margin-top: 0;
    }
    to {
      max-height: 1000rem;
      opacity: 1;
    }
  }
  
  &.error > strong {
    background: @c-error;
    color: @c-errorContrast;
  }

  &.error .input,
  &.error .textarea {
    border-color: @c-error !important;
  }

  &.hidden {
    display: none !important;
  }
}

.inputPin {
  display: flex;

  .input {
    flex: 0 0 @input-height;
    height:  @input-height;
    margin: 0 .6rem 0 0;
    text-align: center;
    box-shadow: inset 0 0 0 100px #fff;
  }

  button {
    flex: 0 0 @input-height;
    height: @input-height;

    svg {
      transition: opacity 0.5s;
      position: absolute;
    }

    &.active .iconEyeShow,
    &:not(.active) .iconEye {
      opacity: 0;
    }
  }
}

// ReactPasswordStrength
div.ReactPasswordStrength {
  padding: 0;
  overflow: hidden;
  border: none;
  font-family: @f-family;
  font-size: 1.3rem;
  width: 100%;
  height: @input-height;

  .ReactPasswordStrength-strength-bar {
    position: absolute;
    left: 1px;
    bottom: 1px;
    top: auto;
    right: auto;
    max-width: calc(100% - 2px);
    border-bottom-left-radius: @input-border-radius;
  }

  &.is-strength-4 .ReactPasswordStrength-strength-bar {
    border-bottom-right-radius: @input-border-radius;
  }

  .ReactPasswordStrength-strength-desc {
    padding: 0.7rem 1.3rem 0 0;
    min-width: 10rem;
    background-color: transparent;
    bottom: 0;
    top: 0;
    line-height: 2rem;
    font-style: normal;
    pointer-events: none;
  }

  .ReactPasswordStrength-input {
    padding-right: 100px;
  }
}

// bootstrap override
.form-group label {
  margin: 0 0 0.2rem;
  color: @c-secondaryText;
  font-size: 1.3rem;
  font-weight: @f-medium;
}
input.form-control {
  color: @input-c-text;
  font-weight: @f-medium;
}

.help-block {
  color: #737373;
  display: block;
  margin-bottom: 1.111rem;
  margin-top: 0.556rem;
}

.form-group .help-block,
.dropzone + .help-block {
  background: @c-error;
  color: @c-errorContrast !important;
  font-size: 1.2rem;
  font-weight: @f-medium;
  padding: 0.5rem 1.5rem;
}

.confirm-input {
  text-transform: uppercase;
}
