.page-assets,
.page-walls {
  display: flex;
  flex-direction: column;

  .wall-dimension-status-wrapper {
    line-height: 2rem;
    padding: 0;
  }
}

.assetsUploadModal {
  --upload-area-size: 40rem;

  width: 80rem;

  .modalContent {
    display: flex;

    .uploadArea {
      min-width: var(--upload-area-size);
    }

    .editArea {
      flex: 1;
      padding-left: 2rem;

      &:empty {
        display: none;
      }
    }

    .dropzone {
      border-radius: 0;
      border: 0.2rem dashed @c-silver;
      display: flex;
      flex-direction: column;
      min-height: 40rem;

      .dropzoneFlash {
        margin: 3rem 1rem -1rem;
      }
    }

    .dropzoneThumbs {
      --grid-cols: 3;
      --grid-gap: 1rem;
      --grid-rows: 3;
      --grid-size-add: calc(2 * 0.2rem);
      --scrollbar-size: 1.5rem;

      --grid-size: calc(
        (
          var(--upload-area-size) -
            (
              (2 + (var(--grid-cols) - 1)) * var(--grid-gap) +
                var(--scrollbar-size) + var(--grid-size-add)
            )
        )
      );
      --item-size: calc(var(--grid-size) / var(--grid-cols) + var(--grid-gap));

      display: grid;
      flex: 0;
      gap: var(--grid-gap);
      grid-template-columns: repeat(var(--grid-cols), 1fr);
      max-height: calc(var(--item-size) * var(--grid-rows) + var(--grid-gap));
      overflow-y: auto;

      &:not(:empty) {
        padding: var(--grid-gap);
      }

      &::-webkit-scrollbar {
        width: var(--scrollbar-size);
      }

      .uploadItem {
        height: 0;
        margin: 0;
        overflow: hidden;
        padding-top: 100%;
        position: relative;
        width: 100%;

        .title {
          position: absolute;
          bottom: 1rem;
          left: 1.2rem;
          right: 1.2rem;
          font-size: 1.2rem;
          color: @c-denimLight;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .image {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transition: background-color 0.5s linear;
        }

        &.selected .image {
          box-shadow: inset 0 0 0 2px @c-primary;
        }

        .circleProgress {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 50px;
          height: 50px;

          circle {
            stroke: rgba(0, 0, 0, 0.2);
            fill: transparent;
            stroke-width: 2px;
          }
          .bar {
            stroke: @c-primary;
            transition: all 0.3s;
            transform: rotate(-90deg);
            transform-origin: center;
          }
        }
      }
    }

    .dropzoneText {
      flex: 1 1 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      color: @c-cloud;
      margin: 3rem;

      button {
        margin-top: 1rem;
      }

      &.--compact {
        flex-direction: row;

        button {
          margin-left: 2rem;
          margin-top: 0;
        }
      }
    }

    .inputBoxUrl {
      max-width: none;
      display: flex;
      flex-wrap: wrap;
      margin: 1.5rem 0 0 0;

      label {
        flex: 0 0 100%;
      }

      .inputBox {
        flex: 1 1 auto;
      }

      .button {
        margin-left: 1rem;
      }
    }
  }

  .editMediaHeader {
    font-weight: @f-normal;
  }
}

// =============================================================================
// Categories tree
// =============================================================================

.categories-tree {
  --indent: 20px;
  --item-size: 26px;
  --level: var(--level, 0);
  --level-push: 2em;

  max-height: 840px;
  padding-top: 15px;
  user-select: none;
  width: 100%;

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  li {
    &.isLeaf {
      .selector {
        visibility: visible;
      }
    }
  }

  li {
    background-color: transparent;
    list-style: none;
    transition: background-color 0.2s;

    &:hover {
      background-color: #eef5ff;
    }
  }
}

// Categories tree, selector
// =============================================================================

.categories-tree {
  .selector {
    --background-color: transparent;
    --border-color: @c-denim;
    --size: 13px;

    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 50%;
    color: @c-white;
    cursor: pointer;
    display: flex;
    height: var(--size);
    left: calc(var(--indent) + var(--level) * var(--level-push));
    place-items: center;
    position: absolute;
    top: calc(0.5 * (var(--item-size) - var(--size)));
    transition-duration: 0.2s;
    transition-property: background-color, border-color;
    visibility: hidden;
    width: var(--size);
    z-index: 1;

    svg {
      fill: currentColor;
    }

    &.isSelected {
      --background-color: @c-primary;
      --border-color: @c-primary;
    }
  }
}

// Category item
// =============================================================================

.categoryItem {
  display: flex;
  flex-wrap: wrap;
  padding-left: calc(2 * var(--indent) + var(--level) * var(--level-push));
  padding-right: var(--indent);
  position: relative;

  .inputBox {
    margin: 0;
    padding-bottom: 1rem;
    min-width: 100%;
  }
}

// Category label
// =============================================================================

.categoryLabel {
  align-items: center;
  color: @c-denim;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 13px;
  font-weight: @f-medium;
  justify-content: space-between;
  line-height: 1.3;
  margin-left: calc((-2) * (var(--indent) + var(--level) * var(--level-push)));
  margin-right: calc((-1) * var(--indent));
  min-height: var(--item-size);
  padding-left: calc(2 * (var(--indent) + var(--level) * var(--level-push)));
  padding-right: var(--indent);

  svg {
    align-self: flex-start;
    min-height: inherit;
    transition: transform 0.3s;
    visibility: hidden;
  }

  &.isActive {
    svg {
      visibility: visible;
    }
  }

  &.isSelected {
    text-decoration: underline;
  }

  &.isSelected,
  &.isToggled {
    svg {
      transform: rotate(90deg);
    }
  }
}

// =============================================================================
// =============================================================================

.uploadMediaGrid {
  .uploadItem:hover {
    .selectorHandle {
      display: inline;
    }
  }
  .uploadItem.selected {
    .selectorHandle {
      background-color: #27d388;
      border: none;

      svg {
        fill: #fff;
        position: absolute;
        left: 2px;
        top: 2px;
      }
    }
  }

  .uploadItem:hover {
    .overlay {
      width: 120px;
      height: 120px;
      position: absolute;
      z-index: 2;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.35)
      );
    }
  }
  .uploadItem {
    width: 100px;
    height: 100px;
    margin: 10px 0 0 10px;
    cursor: pointer;

    .selectorHandle {
      display: none;
      right: 5px;
      bottom: 5px;
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      background-color: rgba(255, 255, 255, 0.26);
      border: 1px solid #ffffff;
      border-radius: 50%;
      z-index: 3;
    }

    &.invalid {
      .image::after {
        background-color: #f79824 !important;
      }
    }
  }
  .dropzone {
    .massSelector {
      margin: 10px 0 0 10px;
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .content.empty {
    padding: 20px;
  }
  .empty {
    .dropzone {
      border: 1px solid #9fbcff !important;
      max-height: 880px;
      overflow: scroll;
    }
  }

  .dropzone.multiselect {
    .uploadItem.selected {
      .selectorHandle {
        display: inline;
      }
      .image {
        box-shadow: inset 0 0 0 2px #27d388 !important;
      }
    }
  }
  .dropzone.singleSelect {
    .uploadItem.selected {
      .selectorHandle {
        display: none !important;
      }
    }
  }
}

.assetsUploadModal.addSharedMedia {
  .conflictWarning {
    align-items: center;
    animation: fadeIn 0.3s;
    background-color: #fff;
    border-left: 5px solid #f79824;
    border-radius: 1px;
    box-shadow: 0 4px 13px rgba(@c-black, 0.22);
    display: flex;
    margin: 15px 10px 5px 10px;
    padding: 13px 18px 13px 0;

    svg {
      width: 80px;
    }
    p {
      margin: 0;
      color: #9f9f9f;
      font-size: 12px;
      line-height: 135%;
      font-weight: 500;
    }
  }
}

.sharedMediaModal {
  .modalContent {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;

    .uploadMediaGrid {
      .uploadItem {
        .image::after {
          top: 5px;
          right: 5px;
          position: absolute;
          content: "";
          width: 10px;
          height: 10px;
          background-color: #27d388;
          border-radius: 50%;
        }
      }
      :not(.empty) {
        .dropzoneText {
          display: none;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      overflow-y: scroll;

      .left {
        --upload-area-size: calc(80rem - (200px + 1.3rem));
        --grid-size-add: 0;

        flex: 1;
        box-shadow: 0 -1px #e5e5e5;
        margin: 1px;
        position: relative;
      }
      .right {
        display: flex;
        flex-direction: column;
        width: 200px;
        box-shadow: -1px -1px #e5e5e5, 0 -1px #e5e5e5;
        margin: 1px;

        .placeholderWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;

          p {
            text-align: center;
            color: #d0d0d0;
            font-size: 11px;
            font-weight: 500;
            padding: 20px;
          }
        }

        .search {
          position: relative;

          input {
            width: 100%;
            outline: none;
            height: 40px;
            border: none;
            box-shadow: 0 1px #e5e5ee;
            padding-left: 10px;
            padding-right: 45px;
            color: #0f152e;
          }
          input::placeholder {
            font-weight: 500;
            color: #d0d0d0;
            font-size: 12px;
          }

          svg {
            position: absolute;
            right: 25px;
            top: 13px;
            transition: opacity 0.2s ease-in-out;
          }
        }
      }
    }

    .buttonBar {
      padding: 10px 20px;
      box-shadow: 0px -1px 0px #eeeeee;
      min-height: 55px;
      background-color: #fff;
      display: flex;
      flex-direction: row;

      .left {
        flex-grow: 1;
      }
      .right {
        flex-grow: 0;
      }
    }
  }

  .dropzone {
    border: none !important;

    .dropzoneText span {
      color: #0f152e;
      font-size: 13px;
      font-weight: 500;
    }

    &.dropping,
    &.dropzone-accept {
      border: 2px solid #9fbcff;
    }
  }

  .assetTitleForm {
    height: 40px;
    box-shadow: 0px 1px 0px #e5e5e5;
    background-color: #fff;
    display: flex;
    align-items: center;

    svg {
      width: 40px;
      height: 10px;
      cursor: pointer;
      transform: rotate(0deg);
      user-select: none;
    }
    svg.showingMetadata {
      transform: rotate(90deg);
    }

    .placeholder {
      margin-left: 10px;
    }

    .extension {
      margin-left: 20px;
      background: #d0d0d0;
      border-radius: 2px;
      padding: 2px 10px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      min-height: 25px;
      min-width: 50px;
    }
    .extension.hasFormat {
      background: #3370ff;
    }

    .inputBox {
      margin: 0;
      flex-grow: 1;

      input {
        border: none;
      }
    }
  }
}

.editSharedAsset {
  width: 80rem;

  .left {
    img {
      width: 100%;
    }
  }

  .metadataBlock {
    width: 100%;
    position: absolute;
    background-color: #fff;
    padding: 10px 20px 20px 20px;
    box-shadow: 0px 1px 0px #e5e5e5;

    tr {
      height: 30px;
      color: #000;
      font-weight: bold;
    }
    span.title {
      color: #878a98;
      font-weight: normal;
    }
  }

  .modalContent {
    .dropzone {
      justify-content: center;
      border: 1px solid #d0d0d0 !important;
      background-color: #fff;
      margin: 20px;
      width: calc(100% - 40px);

      .dropzoneText {
        display: none !important;
      }

      .assetLoading {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.assetEditModal {
  width: 40rem;

  .dropzone {
    border: 0.2rem dashed #d0d0d0;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;

    img,
    video {
      width: 12rem;
      max-height: 20rem;
      margin-right: 2rem;
    }

    .assetPlaceholder {
      width: 12rem;
      height: 12rem;
      margin-right: 1.5rem;
      background: @c-silver;
      display: inline-flex;
      padding: 2rem;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      line-height: 1.2;
    }

    .button {
      margin-top: 1rem;
    }

    .dropzoneFlash {
      margin: 0 0 1rem 0;
      padding: 0.5rem 1rem;
    }
  }
}

.assetEditModal,
.assetsUploadModal {
  .dropzone {
    transition: all 0.2s;

    &.dropping,
    &.dropzone-accept {
      border: 0.2rem dashed @c-primary;
    }

    &.error,
    &.dropzone-reject {
      border: 0.2rem dashed @c-error;
    }

    .dropzoneText {
      text-align: center;
    }
  }

  .mediaSummary {
    font-size: 1.3rem;
    border: 1px solid @c-silver;
    border-radius: @input-border-radius;
    margin: 1rem 0;
    width: 100%;

    td,
    th {
      padding: 0.5rem 1.5rem;
      font-weight: @f-medium;
    }

    th {
      padding-right: 0.5rem;
      color: @c-denimLight;
      font-weight: @f-normal;
      width: 5rem;
      white-space: nowrap;
    }

    tr:first-child td,
    tr:first-child th {
      padding-top: 1rem;
    }

    tr:last-child td,
    tr:last-child th {
      padding-bottom: 1rem;
    }
  }

  .assetLoading {
    display: inline-block;
    width: 12rem;
    height: 7rem;
    line-height: 7rem;
    background: @c-pearl;
    margin-right: 2rem;
    color: @c-secondaryText;
    text-align: center;
    position: relative;
  }

  .assetError {
    color: @c-error;
    text-align: center;
    display: block;
    flex-basis: 100%;

    svg {
      vertical-align: top;
      fill: currentColor;
      margin: -0.3rem 0.8rem;
    }
  }
}

.backgroundEditModal {
  width: 80vw;
  max-width: 1000px;

  .mediaDetail {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 2rem -2rem -2rem 0;
  }

  .mediaEdit,
  .mediaSummary {
    flex: 1 1 0;
    margin: 0 2rem 1rem 0;
    min-width: 300px;

    .inputBox {
      max-width: none;
    }
  }

  .dropzone {
    position: relative;
    overflow: hidden;
    background: @c-white;
    height: 15rem;
    margin-bottom: 1rem;
    justify-content: space-around;
    border: 0.2rem solid @panelBackground;
    transition: none;

    img,
    video {
      width: auto;
      height: auto;
      max-width: none;
      max-height: none;
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      object-fit: cover;
    }

    .assetLoading {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: none;
    }

    .buttonDelete {
      position: absolute;
      right: 16rem;
      top: 2rem;
      margin: 0;
    }

    .dropzoneText {
      text-align: center;
      color: @c-secondaryText;
    }

    .buttonReplace {
      position: absolute;
      right: 2rem;
      top: 2rem;
      margin: 0;
    }
  }
}
