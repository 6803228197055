.page-presentations {
  display: flex;
  flex-direction: column;

  &[class*="page-presentations-edit"] {
    display: flex;
    flex-direction: row;
    min-width: 80rem;

    .pageContent {
      padding: 2rem;
      flex: 1 1 auto;
    }

    .pageAside {
      flex: 0 0 29rem;
      background: @c-white;
      border-left: 1px solid @c-pearl;
      padding: 2rem;
      /* space for zendesk widget */
      padding-bottom: 80px;
      position: relative;
      z-index: 1;

      .pageAsideHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;

        h2 {
          margin: 0;
        }
      }

      form label {
        color: @c-denimLight;
      }
    }
  }

  .icon-scene {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 2px 10px 0 0;
    opacity: 0.6;
    vertical-align: top;
    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16"><path d="M13.6,1.1C12.3,0.4,10.5,0,8.6,0C6.6,0,4.8,0.4,3.5,1.1c-1.5,0.8-2.4,1.8-2.4,3v8.3c0,1.2,0.8,2.2,2.4,3 C4,15.6,4.6,15.8,5.3,16c0,0,0,0,0,0c0,0,0.1,0,0.1,0h0c0.4,0,0.7-0.3,0.7-0.7V9.9c0.7-0.2,1.6-0.2,2.4-0.2c1,0,1.9,0.1,2.6,0.3v5.4 c0,0.4,0.3,0.7,0.7,0.7h0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.6-0.2,1.1-0.4,1.6-0.6c1.5-0.8,2.4-1.8,2.4-3V4.1C16,2.9,15.2,1.8,13.6,1.1 z M4.8,14.4c-1.4-0.5-2.3-1.3-2.3-2.1c0-0.7,0.9-1.5,2.3-2.1V14.4z M4.8,8.8C4.3,9,3.9,9.1,3.5,9.3c-0.4,0.2-0.7,0.4-1,0.6V6.4 c0.3,0.2,0.7,0.4,1,0.6c0.4,0.2,0.8,0.4,1.3,0.5V8.8z M12.6,14.3v-4c1.3,0.5,2.1,1.3,2.1,2C14.6,13,13.9,13.8,12.6,14.3z M14.6,9.9 c-0.3-0.2-0.7-0.4-1-0.6c-0.3-0.2-0.7-0.3-1.1-0.4V7.5c0.4-0.1,0.7-0.3,1.1-0.4c0.4-0.2,0.7-0.4,1-0.6C14.6,7.6,14.6,8.8,14.6,9.9z M11.9,6.3C11.9,6.3,11.9,6.3,11.9,6.3L11.9,6.3c-0.4,0-0.7,0.3-0.7,0.7v1.5c-0.4-0.1-0.8-0.1-1.2-0.2C9.5,8.3,9,8.3,8.6,8.3 s-0.9,0-1.4,0.1c-0.4,0-0.7,0.1-1,0.1V7c0-0.4-0.3-0.7-0.7-0.7v0c-1.8-0.5-3-1.4-3-2.3c0-1.3,2.5-2.7,6.1-2.7s6.1,1.4,6.1,2.7 C14.6,4.9,13.6,5.8,11.9,6.3z"/></svg>');
  }

  .presentations-scenes-connections {
    position: relative;

    .connection {
      margin: 2px;
      padding-left: 20px;
      position: relative;
      border: 1px dashed transparent;

      &.is-being-dragged, &.has-connection-over {
        border-color: #38e4d1;
      }

      .self {
        padding: 5px;
        border-radius: 3px;
        cursor: move;
        margin: 3px 3px 0 3px;
      }

      &:hover .delete {
        opacity: 0.5;
      }

      .delete {
        height: 20px;
        display: inline-block;
        cursor: pointer;
        background: transparent;
        border: none;
        padding: 0 0 0 20px;
        margin: 0 10px;
        position: relative;
        opacity: 0;

        &:hover,
        &:focus {
          opacity: 1;
        }

        &:before,
        &:after {
          content: "";
          width: 14px;
          height: 2px;
          margin-top: -1px;
          background: @warningColor;
          position: absolute;
          left: 2px;
          top: 50%;
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }

    .suggestions {
      width: 200px;
      border-radius: 2px;
      display: inline-block;
      cursor: pointer;
      margin: 7px 0 5px 23px;
    }
  }
}
