.customFieldsDragLayer {
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.page-custom-fields {
  display: flex;
  flex-direction: column;

  .tabHeader {
    padding-right: 16.3rem;
  }

  .pageContent {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 1 1 100%;
    overflow: hidden;

    .contentHeader {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 0 3rem 0;

      .search {
        margin: 0;
      }
    }

    h2 {
      font-size: 2rem;
      font-weight: 400;
      margin: 0;
      display: flex;
      align-items: center;
    }
  }

  .gridRowDnD {
    margin-bottom: 0.5rem;

    &.gridRowDndOver {
      border: 1px solid @c-primary;
      background: #eef5ff;
      &.rowFilter {
        background: #eef5ff;
      }
      .category {
        background: #eef5ff;
      }
    }
  }

  .filtered {
    opacity: 0.2;
    background: none !important;
    cursor: auto !important;

    & + .rowLevel {
      .addItemButton {
        opacity: 0.2;
      }
    }
    &.gridRowDnD {
      opacity: 1;
    }

    .circle:hover {
      border: 1.2px solid #c6c5c5 !important;
      &:before {
        background: none !important;
      }
      svg {
        stroke: #c6c5c5 !important;
      }
    }
    button:hover {
      border: 1.2px solid #878a98 !important;
      &:before {
        background: none !important;
      }
      svg {
        stroke: #878a98 !important;
      }
    }
  }

  .filteredOpen {
    opacity: 1;

    button.addItemButton:hover {
      background: none;
      border: none !important;
      color: @c-primary;
      svg {
        fill: @c-primary !important;
        stroke: none !important;
      }
    }

    .circle:hover {
      border: 1.2px solid #c6c5c5 !important;
      &:before {
        background: none !important;
      }
      svg {
        stroke: #c6c5c5 !important;
      }
    }
    button:hover {
      border: 1.2px solid #878a98 !important;
      &:before {
        background: none !important;
      }
      svg {
        stroke: #878a98 !important;
      }
    }
  }

  .highlighted {
    border: 1px solid @c-primary !important;
    background: #eef5ff !important;

    .gridRow {
      background: none;
    }

    .circle:hover {
      border: 1.2px solid #c6c5c5 !important;
      &:before {
        background: none !important;
      }
      svg {
        stroke: #878a98 !important;
      }
    }
    button:hover {
      border: 1.2px solid #878a98 !important;
      &:before {
        background: none !important;
      }
      svg {
        stroke: #878a98 !important;
      }
    }
  }

  .gridRow {
    height: 5rem;
    padding: 0 1rem;
    margin: 0;
    width: 40rem;
    box-shadow: 0 1px 0 #e1e1e1;
    border-radius: 2px;

    &.categoryForm {
      margin-bottom: 0.2rem;
      display: flex;
      flex-direction: row;
      height: auto;
      min-height: 5rem;
      padding: 1rem;

      .inputBox {
        flex: 1;
        margin: 0 1rem 0 0;
        text-align: left;
      }

      .gridActions {
        .outlined {
          &.danger {
            border: 2px solid @c-error;
            svg {
              stroke: white;
              fill: none;
            }
          }

          &.danger::before {
            background: @c-error;
          }
        }
      }
    }

    .gridIcon {
      flex: 0 0 2.4rem;
      box-sizing: content-box;
      padding: 0;
      margin: 0 1.4rem 0 1rem;
      svg {
        vertical-align: middle;
        opacity: 0.8;
        stroke: black;
      }
      &.opened {
        svg {
          fill: black;
        }
      }
    }

    .gridRowTitle {
      margin: 0;
      padding: 0;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .gridRowSubtitle {
      margin-top: 0.25rem;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1rem;
      font-weight: 400;
      color: @c-denimLight;
    }

    .gridActions {
      display: flex;
      align-items: center;

      .circle {
        border: 1.2px solid #c6c5c5;
        border-radius: 3rem;
        width: 2.5rem;
        height: 2.5rem;
        color: #c6c5c5;
        margin-left: 0.5rem;
        background: none;

        svg {
          width: 0.9rem;
          stroke: #c6c5c5;
          transition: stroke ease 0.2s;
        }

        &:nth-of-type(2) {
          margin-right: 1rem;
        }

        &:hover {
          color: @c-primary;
          border-color: @c-primary;
          background: none;

          svg {
            stroke: @c-primary;
          }
        }
      }

      .outlined {
        background: none;
        border: 1.2px solid @c-denimLight;
        transition: stroke ease 0.2s;
        margin-left: 0.5rem;

        svg {
          width: 100%;
          height: 100%;
          stroke: @c-denimLight;
          z-index: 1;
        }

        &:hover {
          border: 2px solid @c-primary;

          svg {
            stroke: white;
            z-index: 1;
            fill: none;
          }

          &::before {
            background: @c-primary;
          }

          &.danger {
            border: 2px solid @c-error;
          }

          &.danger::before {
            background: @c-error;
          }
        }

        &::before {
          background: none;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }
    }
  }

  .addItemButton {
    border: 1px solid @c-primary;
    color: @c-primary;
    cursor: pointer;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    width: auto;
    font-weight: 500;

    &.child {
      margin: 1rem 0;
      border: none;
      text-decoration: underline;
    }

    svg {
      margin-right: 1rem;
      fill: @c-primary;
    }

    &:hover {
      background: @c-primary;
      color: white;
      svg {
        fill: white;
      }
    }
  }

  .categoryTree {
    flex: 1 1 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 3rem;
    overflow: auto;

    .category {
      cursor: pointer;
    }

    .rowLevel {
      margin-left: 5rem;
      @keyframes example {
        from {
          height: 0;
          opacity: 0;
        }
        to {
          height: 100%;
          opacity: 1;
        }
      }
      animation: example 0.4s;

      .rowActions {
        margin-top: 0.2rem;
        display: flex;
      }
    }

    .rowFilter {
      background: white;
      box-shadow: 0 1px 0 #e1e1e1;
      width: 40rem;

      .rowLevel {
        margin: 0;
        padding: 0 1rem 1rem 1rem;
        display: flex;
        flex-direction: column;

        .gridRowDnD {
          margin: 0 1rem 0.5rem 1rem;
          width: auto;
        }

        .gridRow {
          border-radius: 2px;
          border: 1px solid #eee;
        }
      }

      .gridRow {
        border: none;
        box-shadow: none;
        width: 100%;
        margin: 0;
      }
    }

    .emptyFilters {
      min-height: 15rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      color: @c-denimLight;
      font-size: 1.3rem;

      .dndIcon {
        width: 2.5rem;
        height: 2.5rem;
        border: 2px solid @c-denimLight;
        border-radius: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        padding: 0.4rem;

        svg {
          stroke: @c-denimLight;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .filterPanel {
    flex: 0 0 40rem;
    max-width: 50rem;
    overflow: auto;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
    box-shadow: -1px 0px 13px rgba(0, 0, 0, 0.08);
    padding: 3rem;

    .search {
      width: 25rem;
    }

    .filterPanelContent {
      flex: 1 1 100%;
      padding: 1rem 0 2rem 0;
      align-self: stretch;

      .gridRow {
        width: auto;
      }

      hr {
        margin-top: 5rem;
        border-width: 2px;
      }

      h2 {
        margin-bottom: 3rem;
      }
    }
  }
}

.modalFilters {
  width: 35rem;
  border-radius: 2px;

  .modalContent {
    min-height: 190px;
  }

  .modalFooter {
    justify-content: space-between;
    .danger {
      color: @c-error;
      border-color: @c-error;
      &:hover {
        color: white;
        background: @c-error;
      }
    }
  }

  .editingError,
  .toBeDeleted {
    background: @c-error;
    color: white;
    padding: 0.5rem 1.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    display: inline-flex;
    width: 100%;
  }

  .list {
    list-style-type: none;
    margin: 0.5rem 0;
    padding: 0;
  }

  .listItem {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    background: white;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;

    &.deleteFlagged {
      .danger {
        background: @c-error;
        color: white;
        svg {
          stroke: white !important;
        }
      }
    }

    &.editing {
      background: #eef5ff;
      border: 2px solid @c-primary;
    }

    .button {
      flex: 0 0 auto;

      svg {
        stroke: @c-primary;
        width: 100%;
        height: 100%;
      }

      &:hover {
        svg {
          stroke: white;
        }
      }

      &.danger {
        border-color: @c-error;
        svg {
          stroke: @c-error;
          fill: none;
        }

        &:hover {
          background: @c-error;
          svg {
            stroke: white;
          }
        }
      }
    }
  }

  .listItemName {
    flex: 1 1 100%;
    word-break: break-word;
    margin-right: 2rem;
  }

  .listItemForm {
    display: flex;
    align-items: flex-start;

    .inputBox {
      flex: 1 1 100%;
    }

    .inputBoxButton {
      flex: 0 0 auto;
      margin-left: 1rem !important;
      text-align: right;

      label {
        width: 100%;
      }

      button {
        min-width: 7rem;
      }
    }
  }
}

.modalEditCategory {
  width: 40rem;
  border-radius: 2px;

  .treeLabel {
    margin: 0 0 0.2rem;
    color: #878a98;
    font-size: 1.3rem;
    font-weight: 500;
  }

  .treeNode {
    margin: 1rem 0;
  }

  .treeSubNode {
    padding-left: 2rem;
  }

  .treeItem {
    cursor: pointer;
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 0.3rem;
    svg {
      stroke: black;
      margin-right: 1.5rem;
      margin-bottom: 0.2rem;
    }
    &.opened {
      font-weight: 600;
      svg {
        fill: black;
      }
    }
    &:hover,
    &.selected {
      color: @c-primary;
      font-weight: 600;
      svg {
        stroke: @c-primary;
        fill: @c-primary;
      }
    }
  }
}

.modalAddFieldValues {
  width: 35rem;
  border-radius: 2px;

  .addValueForm {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;

    .inputBox {
      flex: 1 1 100%;
      margin: 0 1rem 0 0 !important;
    }

    .button {
      flex: 0 0 auto;
      margin-top: 2.2rem;
    }
  }

  .list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 1rem 5rem 1rem 0;
      border-top: 1px solid rgba(0, 0, 0, 0.07);
      position: relative;

      &:first-of-type {
        border: none;
      }

      button {
        position: absolute;
        right: 0;
        top: 0.7rem;

        svg {
          fill: @c-error;
          vertical-align: middle;
        }
      }
    }
  }
}

.customFieldsAddCategoryTooltip {
  display: flex;
  align-items: flex-end;
  padding: 0 1rem 1rem 1rem;

  .inputBox {
    margin: 0 1rem 0 0;
    width: 26rem;
  }
}
