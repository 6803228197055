.headerLogin {
  .cmsLogo {
    --height: 1.889rem;
    --static: 100;

    &::after {
      content: none;
    }
  }
}

.loginPage {
  flex-grow: 1;
  padding: 5rem 0 7rem;
  overflow: auto;
  display: flex;
}

.loginBox {
  background-color: @c-contentBackground;
  border: 1px solid @c-contentBorder;
  color: @c-denim;
  font-size: 1.182rem;
  margin: auto;
  padding: 4rem 3rem 5rem;
  position: relative;
  width: ~"min(100%, 30rem)";

  h1 {
    color: @c-black;
    margin: 0 0 3.5rem 0;
    text-align: center;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .button {
    --font-size: 1.091rem;
    --indent: 0.909rem;
    --size: 2.727rem;

    border-radius: 4px;
    font-size: var(--font-size);
    line-height: normal;
    height: var(--size);
    margin: 0;
    padding-left: var(--indent);
    padding-right: var(--indent);

    &:hover {
      text-decoration: none;
    }
  }

  .flash-message {
    line-height: 1.3;
  }
}

.loginSSO {
  align-items: baseline;
  border-top: 1px solid @c-pearl;
  font-weight: @f-medium;
  margin-top: 20px;
  padding-top: 24px;

  .button {
    --font-size: 0.909rem;
    --indent: 0.727rem;
    --size: 2.182rem;
  }
}

.loginAlert {
  bottom: 0;
  color: #6470aa;
  font-size: 1.091rem;
  left: 0;
  margin: 0;
  padding: 1.5rem 3rem;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: lowercase;
  transform: translateY(100%);
}

.login-passwordConfirm .loginBox {
  text-align: center;

  button {
    margin: 2rem 0 0;
  }
}

.login-newUserForm .loginBox {
  width: ~"min(40rem, 100%)";
}

/**
 * Alerts
 */

.alert-container {
  align-items: center;
  align-self: center;
  display: inline-flex;
  flex-flow: column;
  position: absolute;
}

.alert {
  --color: inherit;

  align-items: center;
  background: @c-white;
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(@c-black, 0.05);
  display: inline-flex;
  padding: 1.273rem 1.455rem;

  & + & {
    margin-top: 1rem;
  }

  [class*="ico"] {
    color: var(--color);
    height: 24px;
    margin-right: 12px;
    width: 24px;
  }

  &.--danger {
    --color: @c-error;
  }
  &.--success {
    --color: @c-success;
  }
  &.--warning {
    --color: @c-warning;
  }
}
