/**
 * Page Settings.
 */

// =============================================================================
// Settings page
// =============================================================================

.page-settings {
  display: grid;
  grid-template-columns: 20rem auto;
}

.page-settings {
  .pageHeader {
    align-items: center;
    border-bottom: 1px solid @c-primary;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 6.667rem;
    margin-bottom: 0;
    padding: 0 3rem;
  }
}

.page-settings {
  .pageHeading {
    font-size: 2rem;
    font-weight: @f-normal;
    margin: 0;
  }
}

.page-settings {
  .pageContent {
    flex: 1;
  }
}

.page-settings {
  .contentHeader {
    min-height: 58px;

    &[class] {
      margin-bottom: 0;
    }
  }
}

.page-settings {
  .ReactVirtualized__Grid {
    overflow-y: auto !important;
    padding-top: 3rem;
  }
}

/**
 * Modifiers.
 */

.page-settings {
  &[class*="domains"],
  &[class*="editor-settings"],
  &[class*="scene-names"],
  &[class*="triggers"],
  &[class*="users"] {
    .pageContent {
      padding: 3rem;
    }
  }

  &[class*="domains"],
  &[class*="scene-names"],
  &[class*="users"] {
    .gridRow {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[class*="domains"],
  &[class*="users"] {
    .pageContent {
      display: flex;
      flex-direction: column;

      & > div {
        flex: 1;
      }

      .search {
        align-self: flex-end;
        flex-grow: 0;
        width: ~"min(20em, 100%)";
      }
    }
  }

  &[class*="scene-names"] {
    .gridRow {
      margin-bottom: 0.556rem;
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Submenu
// =============================================================================

.submenu {
  --indent: 8px;

  background-color: @c-white;
  font-size: 1.333rem;
  padding: 20px;
  padding-right: 12px;

  header {
    padding: var(--indent);
    margin-bottom: 16px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    ul {
      --indent: 18px;

      padding-bottom: 0.889rem;

      li {
        margin-top: 0;
      }
    }
  }

  li {
    --rotation: 0;

    margin: 0;

    & + li {
      margin-top: 0.333rem;
    }

    svg {
      height: 1.222rem;
      position: relative;
      top: 0.111rem;
      transform: rotate(calc(var(--rotation)));
      transition: transform 0.3s;
      width: 1.222rem;
    }
  }

  a,
  span {
    --color: @c-denim;
    --weight: @f-medium;

    align-items: baseline;
    color: var(--color);
    cursor: pointer;
    display: flex;
    font-weight: var(--weight);
    justify-content: space-between;
    padding: 8px;
    padding-left: var(--indent);
    padding-right: 12px;
    text-decoration: none;
    transition: color 0.3s;
  }

  a {
    &:focus,
    &:hover {
      --color: @c-primary;
    }
  }

  span {
    &.is-active {
      --rotation: 90deg;
    }
  }
}

.submenu__title {
  color: @c-black;
  font-size: 1.667rem;
  font-weight: @f-bold;

  &[class] {
    margin: 0;
  }
}

.submenu__item {
  &.is-active {
    & > a,
    & > span {
      --color: @c-primary;
      --weight: @f-bold;
    }
  }
}

// Subpage
// =============================================================================

.subpage {
  display: flex;
  flex-direction: column;

  [data-grid] {
    display: grid;
    gap: 0 3rem;
    grid-template-columns: repeat(auto-fit, minmax(~"min(100%, 30rem)", 1fr));
    place-items: start center;
    padding-bottom: 3rem;
    padding-top: 3rem;
  }
}

// Editor settings, form
// =============================================================================

.editor-settings-form {
  @inputSize: 4rem;

  --nymber-size: @inputSize;
  --swytch-size: @inputSize;

  display: grid;
  font-weight: @f-medium;
  gap: 2.667rem 6.667rem;
  grid-template-columns: 31.667rem 20rem;

  [for] {
    user-select: none;
  }

  h5 {
    color: @c-black;
    font-size: 1.444rem;
    margin-bottom: 0.444rem;
    margin-top: 0;
  }

  p {
    color: @c-denimLight;
    font-size: 1.222rem;
    margin-bottom: 0;
  }

  .inputBox {
    margin: 0;
  }

  .combobox__control {
    min-height: @inputSize;
  }
}
