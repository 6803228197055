.scene-editor {
  input {
    font-size: 1.5rem;
    font-weight: @f-medium;
    height: 2.6rem;
    line-height: 2.6rem;
    margin: 0 0.3rem 0 0;
    padding: 0 0 0 0.2rem;
    outline: 0;
    background: #fff;
    border: 1px solid @input-c-border;
    color: #000;
    text-align: center;
    border-radius: @input-border-radius;
    box-shadow: none !important;
    font-family: @f-family;

    &:focus {
      border-color: @primaryColor;
    }
  }

  // SWITCH - checkbox
  .switch {
    @width: 3.2rem;
    @height: 2rem;
    display: inline-block;
    outline: none;
    position: relative;

    input {
      display: inline;
      position: absolute;
      opacity: 0;
      padding: 0;
      margin: 0;
    }
    .handle {
      display: inline-block;
      outline: none;
      flex: 0 0 auto;
      align-self: flex-start;
      width: @width;
      height: @height;
      border-radius: calc(@height / 2 + 2px);
      background: @c-pearl;
      transition: all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      border: 1px solid rgba(0, 0, 0, 0.05);
      position: relative;
      top: -1px;
      overflow: hidden;
      box-sizing: content-box;

      &:before {
        content: "";
        width: @height;
        height: @height;
        background: @c-white;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
        transition: transform 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
    }
    input:checked ~ .handle {
      background-color: @c-success;

      &:before {
        transform: translateX(@width - @height);
      }
    }
    .label-on,
    .label-off {
      position: absolute;
      left: 0;
      top: 0;
      padding-left: 4.5rem;
      white-space: nowrap;
      height: 2rem;
      line-height: 2.2rem;
      font-size: 1.3rem;
      color: @secondaryColor;
      font-weight: @f-bold;
      transition: opacity 0.1s;
    }
    input:checked ~ .label-off,
    input:not(:checked) ~ .label-on {
      opacity: 0;
    }
  }

  // slider
  .rc-slider {
    margin: 1rem 0 3rem 0;

    .rc-slider-mark {
      font-size: 1.1rem;
    }
    .rc-slider-mark-text,
    .rc-slider-dot {
      cursor: default;
    }
    .rc-slider-track {
      background-color: @primaryColor;
    }
    .rc-slider-handle,
    .rc-slider-dot-active {
      border-color: @primaryColor;
      cursor: default;
    }
    .rc-slider-handle:focus {
      box-shadow: 0 0 0 0.5rem lighten(@primaryColor, 20%);
    }
  }
}

/**
 * Checkbox.
 */

.checkbox {
  --backgroundColor: transparent;
  --borderColor: currentColor;
  --color: @c-primaryText;
  --cursor: pointer;
  --markVisibility: hidden;
  --size: 14px;

  align-items: center;
  cursor: var(--cursor);
  display: inline-flex;
  user-select: none;

  &[class][class] {
    color: var(--color);
    margin: 2px 0;
    margin-right: 0.899rem;
    min-width: 0;

    input {
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }
  }

  @media (max-width: 1200px) {
    --size: 12px;
  }

  // States
  &:focus,
  &:hover {
    &:not(.is-disabled):not(.is-readonly) {
      --borderColor: @c-blueDodger;
    }
  }

  &.is-disabled,
  &.is-readonly {
    --color: @c-silver;
    --cursor: default;
  }

  :checked ~ &__shape {
    --backgroundColor: @c-blueDodger;
    --borderColor: @c-blueDodger;
    --markVisibility: visible;
  }
  :checked:disabled ~ &__shape {
    --backgroundColor: @c-silver;
    --borderColor: @c-silver;
  }
  :focus:not(:disabled):not(:readonly) ~ &__shape {
    --borderColor: @c-blueDodger;
  }

  // Control
  &__control {
    display: block;
    flex-shrink: 0;
    margin-right: 4px;
  }

  // Shape
  &__shape {
    background-color: var(--backgroundColor);
    border: 1px solid var(--borderColor);
    border-radius: 2px;
    display: inline-grid;
    height: var(--size);
    place-items: center;
    transition-duration: 0.2s;
    transition-property: background-color, border-color;
    width: var(--size);

    &::before {
      background: transparent center no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M1.643 2.528a.6.6 0 00-.74.944l.74-.944zM3.36 4.636l-.37.473.413.323.375-.365-.418-.43zm3.785-2.842a.6.6 0 10-.836-.86l.836.86zM.903 3.472L2.99 5.11l.74-.945-2.087-1.636-.74.944zm2.875 1.595l3.367-3.273-.836-.86-3.367 3.272.836.86z'/%3E%3C/svg%3E");
      background-position-y: 60%;
      background-size: contain;
      content: "";
      display: inline-block;
      height: 80%;
      visibility: var(--markVisibility);
      width: 80%;
    }
  }
}

/**
 * Number.
 */

.number {
  --borderColor: @c-pearl;
  --color: @c-primaryText;
  --size: 2.667rem;

  color: var(--color);
  display: inline-flex;
  height: var(--size);
  line-height: normal;

  &[class][class] {
    margin-bottom: 2px;
    margin-right: 4px;
    margin-top: 2px;
  }

  // States
  &:focus-within:not(.is-disabled),
  &:hover:not(.is-disabled) {
    --borderColor: @c-blueDodger;
  }
  &.is-disabled {
    [type="number"],
    .number__unit {
      visibility: hidden;
    }
  }

  [type="number"] {
    appearance: textfield;
    background: none;
    border: 0;
    border-radius: 0;
    color: inherit;
    flex: 1;
    font-size: inherit;
    font-weight: inherit;
    height: inherit;
    line-height: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    text-align: right;

    &[type] {
      min-width: 0;
      width: 100%;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
    }
  }

  &__body {
    background: #fff;
    border: 1px solid var(--borderColor);
    border-radius: 1px;
    display: inline-flex;
    justify-content: flex-end;
    padding-right: 0.899rem;
    padding-left: 0.444rem;
    transition: border-color 0.3s;
    width: 7.222rem;
  }

  &__label,
  &__prefix,
  &__unit {
    align-items: center;
    display: inherit;
  }

  &__label {
    margin-left: 0.444rem;
  }

  &__prefix {
    color: #b6b6b6;
    margin-right: 0.444rem;
    padding-left: 0.222rem;
    path {
      stroke: var(--color);
    }
  }

  // &__unit {}
}
