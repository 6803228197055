.scene-editor {
  flex: 1;
  margin-top: -1px; // to cover 1px header border
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-family: @f-family;
  color: @textColor;
  user-select: none;

  h1 {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: @f-bold;
    margin: 0;
    display: inline-block;
    position: relative;
  }

  h2,
  h3 {
    font-size: 1.8rem;
    line-height: 1.3;
    font-weight: @f-medium;
    color: @headlineColor;
    margin: 0;
    padding: 1rem 0 0.7rem 0;
    position: relative;
  }

  h3 {
    font-size: 1.6rem;
    color: @secondaryColor;
    padding: 0.5rem 0 0.3rem 0;
  }

  .flexBox {
    display: flex;
  }
  .flex50 {
    flex: 0 0 50%;
  }

  .critical-error {
    font-weight: bold;
    flex-grow: 1;
    display: flex;
    align-self: center;
    align-items: center;
  }
}
