.page-triggers {
}

.triggersList {
  &,
  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
  }

  .gridRow {
    animation: fadeIn 0.3s var(--delay, 0.1s) both;
    height: auto;
    margin-bottom: 0.556rem;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 1.111rem;
    padding-top: 1.111rem;
  }

  .gridCell {
    padding-left: 2.222rem;
    padding-right: 2.222rem;

    &:first-child {
      max-width: 35%;
    }
    &:last-child {
      max-width: 35%;
      min-width: 12em;
    }
  }

  &__items {
    animation: fadeIn 0.2s both;
    color: @c-denimLight;
    margin-bottom: 1rem;
    margin-top: -0.5rem;
    width: 100%;

    .buttonAlert {
      border: 1px solid @c-error;
      color: @c-error;

      svg {
        fill: none;
        stroke: currentColor;
      }

      &:focus,
      &:hover {
        background-color: @c-error;
        color: @c-white;
      }
    }
  }

  &__toggle {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    font-size: 1.556rem;
    font-weight: 600;
    position: relative;

    &[class] {
      margin: 1em 0 1em;
    }

    svg {
      margin-left: 0.35em;
      transition: transform 0.3s ease-in-out;
      width: 5px;
    }

    &.is-active {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.triggersModal {
  .modalContent {
    overflow: inherit;
  }

  &.primary {
    min-width: 600px;

    .modalContent {
      min-height: 30rem;
    }
  }
  &.is-bleeding {
    overflow: visible;
  }

  &.has-footer {
    .modalContent {
      padding-bottom: 10rem;

      .modalFooter {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        margin-top: 3rem;
        padding: 2rem;
      }
    }
  }

  .grid {
    display: grid;
    gap: 1rem 1rem;
    grid-template-columns: 3rem 1fr 1fr;
    margin-bottom: 1rem;

    & > div {
      display: contents;
    }

    .inputBox {
      margin: 0;
    }
  }

  .input {
    &::placeholder {
      text-align: left;
    }
  }

  .combobox__value-container--has-value {
    font-weight: 500;
    padding-left: calc(1.25rem - 2px);
  }

  .combobox__group {
    &-heading {
      color: inherit;
      font-size: inherit;
      font-weight: 700;
      text-transform: none;
    }

    .combobox__option {
      padding-left: 30px;
    }
  }
}

.addTrigger {
  width: 100%;

  svg {
    height: 1.25rem;
    margin: 0;
  }
}

.deleteTrigger {
  border: 0;
  cursor: pointer;
  right: auto;
  position: relative;
  top: auto;

  &[class][class][class] {
    color: @c-denimLight;

    &:focus,
    &:hover {
      background: none;
      color: @c-primary;
    }
  }

  svg {
    stroke: currentColor;
    height: 2rem;
  }
}
