// MAIN PANEL without room editor
.scene-editor {
  &.preview .main-panel {
    transform: translateX(0);
    width: 100vw;
  }

  .main-panel {
    background: #fff;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    position: relative;
    transform: translateX(-1 * 100%);
    width: calc(100vw - @panelWidth);
    z-index: 1;

    .left-top-bar {
      --topBarHeight: 22.222rem;

      background-color: @panelBackground;
      border-left: 1px solid @c-contentBorder;
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      font-size: 1.222rem;
      min-height: var(--topBarHeight);
      position: relative;
      z-index: 1;

      fieldset,
      .fieldset {
        animation: fadeIn 0.3s both;
        margin: 0;
        padding: 0.75rem 1rem;
      }

      @media (min-width: 1100px) {
        --topBarHeight: 11.111rem;
      }
    }
  }

  .asset-config {
    --inputHeight: 2.667rem;

    color: @c-primaryText;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    position: relative;

    h3 {
      font-size: 1em;
      font-weight: 600;
      margin-bottom: 0.25rem;
      margin-top: 0;
    }

    .checkbox {
      line-height: var(--inputHeight);
    }

    .savingIndicator:after {
      content: "";
      display: inline-block;
      margin: -0.2rem 0 0 1rem;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      background: @successColor;
      position: relative;
      top: -1px;
      opacity: 0;
      transition: background 0.2s, opacity 1s ease-in;
    }

    &.saving .savingIndicator:after {
      background-color: @warningColor;
      opacity: 1;
      transition: none;
    }

    &.failed .savingIndicator:after {
      background-color: @alertColor;
      opacity: 1;
      transition: none;
    }
  }

  .buttonConfigPanel {
    --backgroundColor: transparent;
    --color: inherit;

    background: var(--backgroundColor);
    border-color: var(--backgroundColor);
    border-radius: 2px;
    color: var(--color);
    height: var(--inputHeight);
    margin: 2px 4px;
    margin-left: 0;
    width: var(--inputHeight);

    &[class][class] {
      box-shadow: none;
      padding: 0;
    }

    &:disabled {
      background: transparent !important;
      color: @c-silver !important;
    }
    &:hover:not(:disabled) {
      --backgroundColor: @c-pearl;
    }
    &.selected:not(:disabled) {
      --backgroundColor: @c-blueDodger;
      --color: @c-white;
    }

    svg {
      fill: none;
      height: 100%;
      width: 100%;
    }
  }

  .buttonToggleConfigPanel {
    display: inline-block;
    min-height: 0;

    &[class] {
      margin: 0;
    }

    .toggleLabel {
      color: inherit;
      margin: 0;
    }
    .toggleHandle {
      display: none;
    }

    input:checked ~ .toggleLabel .buttonConfigPanel {
      --backgroundColor: @c-blueDodger;
      --color: @c-white;
    }
  }

  .actionConfigFieldset {
    .button {
      --size: 6.444rem;

      align-items: center;
      display: flex;
      flex-flow: column;
      font-size: inherit;
      height: var(--size);
      justify-content: center;
      line-height: 1.2;
      padding: 0;
      text-transform: capitalize;
      white-space: normal;
      width: var(--size);
    }

    svg {
      height: 1.778rem;
      margin: 0;
      position: relative;
      top: -2px;
    }
  }

  .textConfigFieldset {
    flex-grow: 1;

    .fontColorAndSize {
      display: flex;

      .inputBox {
        --borderRadius: 1px;

        margin: 2px 0;
        margin-right: 4px;
        width: calc(4 * var(--inputHeight) + 3 * 4px);

        &:nth-child(2) {
          width: calc(2 * var(--inputHeight) + 1 * 4px);
        }

        .combobox {
          input {
            height: 100%;
          }
        }
        .combobox__control {
          border-radius: var(--borderRadius);
          height: var(--inputHeight);
          min-height: var(--inputHeight);
        }
        .combobox__value-container,
        [class*="indicator"] {
          height: var(--inputHeight);
        }
        [class*="dropdown-indicator"] {
          background-color: transparent;
          background-image: url("@{iconCaretDown}");
          background-size: 50%;
          border-radius: var(--borderRadius);
          top: -1%;
          width: 1.778rem;
        }
        .combobox__single-value + div {
          padding: 0;
          height: 100%;
          overflow: hidden;
        }
        .combobox__menu {
          border-radius: var(--borderRadius);
        }
      }

      .colorButton {
        background: @c-blueDodger 50% 50% / cover no-repeat border-box;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA\
          AEAAAABACAMAAACdt4HsAAADAFBMVEVMm///NDQ62//yM9TDM///ezNFsv/s7DXz7DR\
          T/ERL/UU7/kc+zP/k7TbwM+v/gzNsM//xM+M16/9WdP//azM80/9Zav+6M/9z+UGyM/\
          /0M8TzM8zc7jdc+0Nr+UIz9dr/czNj+kPLM/9bY/+DM/96M/+rM/9zM//0M7v1M6z/U\
          zOS9T6a9T208jpUev+88TqTM/96+EDD8TldXP+LM//4M4z/QzND/Ub/XDP/YzNiS/9k\
          Q//3M5yL9j+B90BBw/8z97zyM93J8Dj/SzP7M2P96jP2M6T1M7RQiv8z/WFmPP/wM/J\
          Hrf/5M3v/4TPtM//5M4T/rTMz/Wn7M2z9M0sz/HH6M3T+Mzv/vDP/OzMz/WUz/W3/tj\
          Mz+pn/yTMz/lUz/Hr/xTP8M1oz/HX9M1Mz+4kz+4Ez/00z/1H+M0T/mDPpM///2TP/u\
          TP/sjMz+o7/1DP/wjNRhf8z+4Uz/lrdM//V7zelM/8z+LH/pTNfVv//njP/qTMz+Z40\
          8f8z+pIz/l3/zjNJpv8z9eAz+aT/jTM35v8z+aFNlv//oTP/3TMz98Ez+pUz+ar/0TP\
          3M5VCvv/hM/8z9tUz9tHvM/oz9OY0/0gz9O1Okf8z9s3RM/8z8/n/ljPR7zgz9On/iD\
          P/kTPlM/8z98Uz9slKof/WM/8z+LlTgf8z+K0z+LWm9Dw44f8z9PGn8zyfM/+G9z8z/\
          H0z8/bZM///5jP46zRDuv9Pjv+ZM/8/xv+v8zsz9PT76zMz8/z3M5LO7zig9D0z+343\
          /0cz/0ppOf//qTRgUP80/mr/rjQz/Hf/uTT/pTRIqv//kzNEt//wM/Z990A0/1o1/k1\
          YcP9oNv8z/XH/mzOfNP8z98nwM+8z97g+/HLR8Dg0/InlM/OXUus09evvM/09/VL37D\
          T+6DMz9Pf/5jQ+/kf/vzP/tTQz/IA0/H40/IE0+MAz+a00+5P70zT/0TTfM/8z9eUz+\
          af/2TT/lDOY6Ecz98cz+Lcz8/I08vEz9PObM/809PTgNP826fUBPmTtAAAIVUlEQVR4\
          2mSSeVyU1xWGz6CiKEFGNpVANIKCQaKJIFuAKCCobAKCIi4oyqIigjqAKBIRcIJGBQc\
          EwyIhBbVoosYILkTN5tYgVNI2iSkpmLZpa6i1S9r0nHPvNzPoFfzxz/Pc933vB79JjO\
          te8+mq0+vWZadkZu56M6mgZLD8F5p/Bv658X714UMvvFH5tu7kmY6OcXtPmNiYm1946\
          9xt00VFTU1QWnU+dMuWd2Hx4sREaUhJYUHBHhS0BAY2HmfBjyzYKQQXAt5C/mYR8VXE\
          u8MyNMTFoeEqRkjJ/IuIoGlpDtQ+UQT/qSGBiY0NBTA1Zb60KjQUeXcIW/YxG1advso\
          lSEARUNB4XwouosAoAPKg8FYQFiYzKCWSkgr2DFIHbaPYQKe7SBOYmIgAi27yAJK3gk\
          LFIEqICNQBBcc5gU53pmbnuBMmJubmAbigGOC85LsgRxrEkNkcoWTPoEbTrNUKwTe8I\
          TeQBfR8V5ct5CgGGvJ7KsER6CG196s5AQroDTgAFSgtNfC2sHJlTo7egBF+l5lJEbjD\
          Eymo4TfgAFzAwE+ZAqloKCyULZQS9Cm0NAtBJb6iaBDAC9KAgrdF/lVYT4acQpmBhuQ\
          I2KEZn4EF/8VHxAk5gMJbSR4FBsPHifQUSgTNl4H4jiS42MENKEBRk+F+wvPy4MhH61\
          O5BmXoRgOV4Ag4wnEUvK37rgMbUAB6garzdH+Xwt+ATjSsF4YwMuAMKSkU4QcNPgMJT\
          n6HnyEHoALEuyv8jRs+MKbzCCkUA8+AT0kdmkUCfARswAHoAd4V8/H1Pj4+0N8/plMa\
          chRDNkUY1LRon5DgX9wAA+AAkleu9/H1hTlz+sewQtTgpxARcITG6hfeoA1PYAAqgAN\
          Knq/39fVdAKNHk6JTbEmGxXH4PWGEknKNEJzBBuYBVEDh6XqBL7gHZqONFPwY+JhXsz\
          HC4A/NWimgADiAwsvrF1y7d88SzMwMClqCDN2rvsenLClv0VYf+lFXgw0Cbt9sYt7WV\
          rmecUtLcHAwYwcqZAjKgDPsKhhsCTyOgg5scM4UByQe4+N4etxyGkx2cGAHKShE6spf\
          oWHN6ezMpD0aEpzs2GsTgAUkn2eET8MDjpMnT9YrsMdH63EINFzFCOXNT1CwEwMsgqo\
          t7l0Yn9LjdAo+zRkcHUkhHKygEGT4E0ZoaTxUeWYcBmgiXlyPt0vamQ6MHz/ekSV6BY\
          VAw6fZuwo0WhTsNT9XVMr8s7izE8yaNX6IgxVYY1niqpSk8sDDlTUmAYtgi7vtlDwfH\
          8b1tJOzk5MT+PvfvTuLLdKBMeaMOZKaE9Z9OrPky8OVO21uQ6i7LV6v4IKWB9ra2vz9\
          hYUl6CBF5xcrw7rXJWmqdXsDikKt8HpfxAUt0dlOs/HAa3TahIYlwoGK1LC4lMH7OhP\
          T81ZTbvhesxRXS1iembPhJT6vSQ9bSOLgMLr/i8I1Sdp/XyjtyiNcwuLembNnygNu4r\
          zkZjCh5i45zPpz/tjyc5Otz7VpDIs7jc4H+A+u8HHDHze3K1LGojb/WY5m37554dVrz\
          sQakA+Mz0/QeurUqVb+wV/6X/zZ2kq/rVf+8fVPv3z2/NrwJ3xodGZ8OIPPy3heeeXy\
          sedH/rUu6qujw+3sJkyYSmf71O30Y3xghjwvM0bg3LlzL/922/PPjTy7b3WZV/L83oN\
          HXxyOErTgmSpUUjd1O0gMQYFePrYN4edGjuqL2D+QO3Z6rMdEi4xH75CDJHYTnvIAg4\
          Qie3kbwkyf7YvYsCJhR731vLQlIfbqioat7x19kRwsUSx0QKLHjjHM9Kh2xPev2Lijd\
          oS3Z3yQh6u9+k7kwa3vvaM4hEVoQLISHomXB/dF7Nu8O2t5dE/dJC/P+ORwPxd79cLI\
          DFaggyXSYWcHgkWY8FG/bw9+P2IfXo98fvFY6+meqrVLPEJchql7KyIbhipEEjCiBR6\
          xYfPuFQPLo8tq6yZ5T1+qig2K8nOdaK+2qIjMkApySMlwEDDSo9rb+wjH61cjn5tePG\
          KSFwswwvyJw163uPMAQ5BCxBAOIBrxs+3BhO+j67MSHkbn9tSPHWHtNW+pKi15SdSBE\
          BcyLOQQj4RCOoCjG/D9zH+Sm3+rbgQ2IMHamHAPP1cyqBdWPMAxD241UgBdruDIY3zm\
          a4sxgPd0z6Wq+LVBFGG+iz0aerFGhqJgB+Dlevxvu5Hf+PCTHWXp9RjA2osSoCAmCiP\
          Mn8iGhWRoOPg/VHAMCA5+n3CRfkXWwGfE99RzAC9OEJscEx51KUQaLLhGQwOnQAX0/Y\
          GnZ351VgLf31NbTAG8veYJAXbw8AvBGYQBa9CYuCYqIEKW363nc/OxwFjRAAWqWOwQ7\
          nEAS0hD752KSO5BCkD875tl/ISNmwSPBbjBPE+VCp8hKEZEUAxcQ/R4BBv01w8kLGe+\
          53ExB9ALqANF4BLSIELge8AGcb3Cf44DiADWXrihQSAjuLCBhqAtsQfw9QofnVtGBTg\
          ATiAE8TQCRrgkSwhDr6iRAXw9zYf8dRqAC4gGekFyDEU4EPKUAWtEgrye+Ojrn+dzAQ\
          7AEyiCoCU4I0VwdTEyUA0w4nfklvWk31ICeMtXVMWnoYAiXDogSuBTDHtdTvkAVg/lH\
          +MngAFEA0UgRqAIfq5DDFgDsoz4/PTaehlgqCAZI4TLCE8ZYMCI76mlAiIANTAkSJYR\
          xI48gzRAAn6+mzYJPv2WUYBnBeEe9DUNMagtYONngqcBlAKKwFMI4mPxU9KvwCWkQa1\
          Ww3KF5xeskwJvMQELVGlpa2WHKBmBZxAZ4CH2j2Y+vbaYF+AGxgJDB1pBKSENQPdfZ/\
          5xveRlg6cE+ghKCWH4vwADAN0d2/zHc8ugAAAAAElFTkSuQmCC");
        border: 1px solid #eee;
        height: var(--inputHeight);
        margin: 2px 0;
        padding: 0;
        width: var(--inputHeight);

        &:focus-within,
        &:hover {
          border-color: @c-blueDodger;
        }
      }

      .chrome-picker {
        position: absolute;
        z-index: 999;
      }
    }
  }
}

// On-click action modal
.modalOnClickAction {
  &.modalBox .listBox {
    margin-bottom: 0;

    .listBoxItems.listBoxItemsScroll {
      height: 30rem;
    }
  }
}
