.page-users {
}

.modalInviteUser .modalContent {
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }

  p {
    text-align: center;
    margin-bottom: 3rem;
    font-weight: @f-medium;
  }

  .right {
    float: right;
  }

  .inputBox,
  .toggle {
    max-width: none;
  }

  &.modalContent .button {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

[data-qa="modal-dialog-confirm-btn"] {
  background-color: @c-primary !important;
}
