.toggle {
  @width: 3.2rem;
  @height: 2rem;
  min-height: @input-height;
  display: inline-block;
  cursor: pointer;
  
  &.toggleWithLabel {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1rem 0;
    max-width: 35rem;
  }

  input {
    opacity: 0;
    position: absolute;
    top: -1000vh;
    left: -1000vw;
  }

  .toggleHandle {
    display: inline-block;
    outline: none;
    flex: 0 0 auto;
    align-self: flex-start;
    margin-top: (@input-height - @height) / 2;
    width: @width;
    height: @height;
    border-radius: calc(@height / 2 + 2px);
    background: @c-pearl;
    transition: all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    border: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;
    top: -1px;
    overflow: hidden;
    box-sizing: content-box;
    cursor: pointer;

    &::after {
      content: "";
      width: @height;
      height: @height;
      background: @c-white;
      position: absolute;
      left: 0px;
      top: 0px;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      transition: transform 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
  }

  input:checked ~ .toggleHandle {
    background: @c-success;

    &::after {
      transform: translateX(@width - @height);
    }
  }

  &:hover {
    .toggleLabel {
      color: @c-primaryText;
    }

    .toggleHandle {
      box-shadow: @boxShadowLighten;
    }
  }

  input:disabled ~ .toggleHandle {
    opacity: 0.5;
  }

  .toggleLabel {
    color: @c-secondaryText;
    font-size: 1.3rem;
    font-weight: @f-medium;
    margin-right: 2rem;
    flex: 1 1 100%;
    margin-top: (@input-height - @height) / 2;
    transition: color 0.15s linear;

    small {
      display: block;
      color: @c-primaryText;
      &.warning {
        color: @c-warning;
      }
    }
  }
}
