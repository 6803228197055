.avatar {
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.5rem;
  font-weight: @f-normal;
  border-radius: 50%;
  display: inline-grid;
  place-items: center;
  outline: none;
  overflow: hidden;
  text-align: center;
  text-decoration: none !important;
  color: @c-avatarText !important;
  background-color: @c-avatarBackground;
  position: relative;
  transition: box-shadow 0.1s;
  cursor: default;

  .userAvatar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
a.avatar:hover {
  box-shadow: @boxShadowLighten;
}
