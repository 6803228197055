a,
.link {
  color: @c-primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

button {
  outline: 0;
  border: 0;
  padding: 0;
  background-size: contain;
  font-family: @f-family;
  background-color: transparent;
}

.button {
  height: @input-height;
  color: @c-primaryContrast;
  background: @c-primary;
  background-position: center center;
  background-repeat: no-repeat;
  border: 1px solid transparent;
  border-radius: @input-border-radius;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  margin: 0;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  font-size: 1.3rem;
  font-weight: @f-medium;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
  user-select: none;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, @c-primaryContrast 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }

  &:active::after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
  }
  &.noRipple::after {
    content: none;
  }

  &:hover:not(.buttonOutlined) {
    box-shadow: @boxShadowLighten;
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &.buttonSecondary {
    background-color: @c-pearl;
    color: @c-black;

    &.buttonOutlined:not(:hover) {
      border-color: @c-pearl;
      color: @c-black;
    }
  }

  &.buttonAlert {
    background-color: @c-error;
    color: @c-errorContrast;

    &.buttonOutlined:not(:hover) {
      border-color: @c-error;
      color: @c-error;
    }
  }

  &.buttonRounded {
    border-radius: @input-rounded-border-radius;
  }

  &.buttonOutlined:not(:hover) {
    border-color: @c-primary;
    background: transparent;
    color: @c-primary;
  }

  + .button {
    margin-left: 1rem;
  }

  img,
  svg {
    fill: currentColor;
    height: 24px;
    margin: 0 0.8rem 0 -0.8rem;
    pointer-events: none;
  }

  &.buttonIcon {
    width: @input-height;
    padding: 0;

    img,
    svg {
      margin: 0;
    }
  }

  &.inProgress,
  &.inProgress:disabled {
    color: transparent !important;
    border-color: transparent !important;
    background: @c-pearl url("data:image/svg+xml;base64,\
    PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3Lncz\
    Lm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjI4cHgiIGhlaWdodD0iMjRw\
    eCIgdmlld0JveD0iMCAwIDI4IDI0IiA+PGFuaW1hdGUgYXR0cmli\
    dXRlTmFtZT0ib3BhY2l0eSIgZnJvbT0iMCIgdG89IjEiIGR1cj0i\
    MXMiIGJlZ2luPSIwLjVzIiByZXBlYXRDb3VudD0iMSIgLz48Y2ly\
    Y2xlIGZpbGw9IiMwMDRiZmYiIGN4PSI0IiBjeT0iMTIiIHI9IjMi\
    PjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5z\
    Zm9ybSIgZHVyPSIxcyIgdHlwZT0idHJhbnNsYXRlIiB2YWx1ZXM9\
    IjAgMzswIC0zOzAgMyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUi\
    IGNhbGNNb2RlPSJzcGxpbmUiIGtleVNwbGluZXM9IjAuNCAwIDAu\
    NiAxOyAwLjQgMCAwLjYgMSIgYmVnaW49IjAuMSIgLz48L2NpcmNs\
    ZT48Y2lyY2xlIGZpbGw9IiMwMDRiZmYiIGN4PSIxNCIgY3k9IjEy\
    IiByPSIzIj48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1l\
    PSJ0cmFuc2Zvcm0iIGR1cj0iMXMiIHR5cGU9InRyYW5zbGF0ZSIg\
    dmFsdWVzPSIwIDM7MCAtMzswIDMiIHJlcGVhdENvdW50PSJpbmRl\
    ZmluaXRlIiBjYWxjTW9kZT0ic3BsaW5lIiBrZXlTcGxpbmVzPSIw\
    LjQgMCAwLjYgMTsgMC40IDAgMC42IDEiIGJlZ2luPSIwLjIiIC8+\
    PC9jaXJjbGU+PGNpcmNsZSBmaWxsPSIjMDA0YmZmIiBjeD0iMjQi\
    IGN5PSIxMiIgcj0iMyI+PGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmli\
    dXRlTmFtZT0idHJhbnNmb3JtIiBkdXI9IjFzIiB0eXBlPSJ0cmFu\
    c2xhdGUiIHZhbHVlcz0iMCAzOzAgLTM7MCAzIiByZXBlYXRDb3Vu\
    dD0iaW5kZWZpbml0ZSIgY2FsY01vZGU9InNwbGluZSIga2V5U3Bs\
    aW5lcz0iMC40IDAgMC42IDE7IDAuNCAwIDAuNiAxIiBiZWdpbj0i\
    MC4zIiAvPjwvY2lyY2xlPjwvc3ZnPg==") no-repeat center / 2.8rem 2.8rem !important;
    transition: all 1s 0.5s ease;
    animation: backgroundSize 1s linear;
  }

  @keyframes backgroundSize {
    0% { background-size: 0 0; }
    70% { background-size: 0 0; }
  }

  &.buttonIcon {
    background-size: 2rem 2rem !important;
  }
}

.button:disabled {
  color: @c-white !important;
  background: @c-silver !important;
  border-color: transparent !important;
  box-shadow: none !important;
  cursor: not-allowed;

  &.buttonOutlined {
    border: 1px solid @c-pearl !important;
    background: none !important;
    color: @c-silver !important;
  }
}

.buttonPlus {
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 2px solid @c-denimLight;
  background: none;
  transition: border 0.1s linear;

  &::after,
  &::before {
    content: '';
    width: 1.5rem;
    height: 2px;
    position: absolute;
    display: block;
    background: @c-denimLight;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: background 0.1s linear;
  }

  &::before {
    width: 2px;
    height: 1.5rem;
  }

  &:hover {
    border-color: @c-primary;
    border-width: 2px;

    &::after,
    &::before {
      background-color: @c-primary;
    }
  }
}

.buttonDialog {
  @border-radius: 6px;
  position: relative;
  display: inline-block;
  width: max-content;

  .button {
    margin: 0;
  }

  .buttonDialogBox {
    display: none;
    position: absolute;
    left: calc(100% + 20px);
    top: 50%;
    transform: translateY(-50%);
    border-radius: @border-radius;
    background-color: @c-white;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
    width: 20rem;
    padding: 0;
    animation: show-popup .3s ease;
    z-index: 101;

    &::before {
      content: '';
      width: 1rem;
      height: 1rem;
      background: @c-white;
      box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
      position: absolute;
      z-index: -1;
      left: -0.5rem;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    &.isOpen {
      display: inline-block;
    }

    button,
    .itemDialog {
      padding: 0 2rem;
      color: @c-black;
      background: none;
      width: 100%;
      height: 4rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: none;
      border-radius: 0;
      transition: color 0.15s linear;
      text-align: left;
      font-weight: @f-normal;
      justify-content: flex-start;
      position: relative;

      &:hover,
      &:focus,
      &.active {
        color: @c-primary;
      }

      &::before {
        content: "";
        position: absolute;
        height: 1px;
        bottom: 0;
        left: 1rem;
        right: 1rem;
        background: @c-pearl;
      }

      &:first-of-type {
        border-radius: @border-radius @border-radius 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 @border-radius @border-radius;

        &::before {
          content: none;
        }
      }
    }

  }
}

.closeBtn {
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 2rem;
  height: 2rem;
  background: none;
  border: none;
  box-shadow: none;

  &::after,
  &::before {
    content: '';
    height: 0.2rem;
    width: 2rem;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 2px;
    transition: all .1s linear;
    background-color: @c-denimLight;
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }

  &:hover::after,
  &:hover::before {
    background-color: @c-primary;
  }
}

@keyframes show-popup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button {
  &.is-enter,
  &.is-leave {
    transition-duration: 0.5s;
    transition-property: opacity, visibility;
    transition-timing-function: ease;
  }
  &.is-enter {
    opacity: 1;
    visibility: visible;
  }
  &.is-leave {
    opacity: 0;
    visibility: hidden;
  }
}
