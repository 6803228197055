@font-face {
  font-family: 'Inter UI';
  src: local('Inter UI Regular'), 
       local('Inter-UI-Regular'),
       url('./fonts/Inter-UI-Regular.woff2') format('woff2'),
       url('./fonts/Inter-UI-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Inter UI';
  src: local('Inter UI Medium'), 
       local('Inter-UI-Medium'),
       url('./fonts/Inter-UI-Medium.woff2') format('woff2'),
       url('./fonts/Inter-UI-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Inter UI';
  src: local('Inter UI Bold'), 
       local('Inter-UI-Bold'),
       url('./fonts/Inter-UI-Bold.woff2') format('woff2'),
       url('./fonts/Inter-UI-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
/*
@font-face {
  font-family: 'Inter UI';
  src: local('Inter UI Black'), 
       local('Inter-UI-Black'),
       url('./fonts/Inter-UI-Black.woff2') format('woff2'),
       url('./fonts/Inter-UI-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
*/
