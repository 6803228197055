[data-badge]:after {
  content: attr(data-badge);
  display: inline-block;
  font-style: normal;
  font-weight: @f-bold;
  background: @c-error;
  color: @c-errorContrast;
  font-size: 1.1rem;
  height: 2rem;
  min-width: 2rem;
  line-height: 2rem;
  border-radius: 1rem;
  text-align: center;
  padding: 0 0.5rem;
  margin: 0 -1.2rem 0 0.8rem;
}
