// 3D SCENE/ROOM EDITOR
.scene-editor {
  &.preview .view {
    .asset:active:before,
    .asset:active:after {
      content: none !important;
    }
  }

  .view {
    flex: 1 1 auto;
    background: @sceneBackground;
    overflow: hidden;

    .wall-navigation {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      .button {
        cursor: pointer;
        position: absolute;
        height: 5rem;
        width: 5rem;
        top: calc(50% - 2.5rem);
        border: 1.5rem solid gray;
        border-left: none;
        border-bottom: none;
        opacity: 0.7;
        transition: opacity 0.1s linear;
        background: none;

        &:hover {
          opacity: 1;
          box-shadow: none;
        }

        &.backward {
          left: 3rem;
          transform: rotate(-135deg);
        }

        &.forward {
          right: 3rem;
          transform: rotate(45deg);
        }
      }
    }

    .preview-instance-selector {
      position: absolute;
      right: 2rem;
      top: 2rem;
      text-align: right;

      .button-fullscreen,
      .button-fullscreen-exit,
      .button-instance-toggle {
        width: 4.2rem;
        height: 4.2rem;
        line-height: 2.4rem;
        border-radius: 2.1rem;
        text-align: center;
        background: @primaryColor;
        color: @primaryContrastColor;
        padding: 0;
        transition: box-shadow 0.1s linear;
        &:hover {
          box-shadow: inset 0 0 10rem rgba(255, 255, 255, 0.15);
        }
        svg {
          vertical-align: top;
        }
      }
      .button-fullscreen-exit {
        background: @alertColor;
        color: @alertContrastColor;
      }
      .button-instance-toggle {
        width: auto;
        padding: 0 1rem 0 1.8rem;
        margin-right: 1rem;
        background: #fff;
        color: #333;
        font-weight: bold;
        svg {
          padding-left: 0.3rem;
        }
      }

      .list {
        position: absolute;
        top: 4.5rem;
        right: 5rem;
        min-width: 15rem;
        border-radius: 0.5rem;
        overflow: hidden;
        overflow-y: auto;
        background: @c-white;
        max-height: calc(100vh - 15rem);

        button {
          display: block;
          color: black;
          background: @c-white;
          width: 100%;
          text-align: left;
          max-width: 50rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 1rem 2rem;
          &:hover {
            background: @c-primaryLight;
          }
          &.selected {
            background: @c-primary;
            color: @c-primaryContrast;
          }
        }
      }
    }

    .scene {
      position: absolute;
      left: 50%;
      top: 50%;
      transition: all 1s ease-in-out;
    }
    .room {
      transform-origin: 50% 50% 0;
      transform-style: preserve-3d;
      transition: all 1s ease-in-out;
      display: none;
    }

    .wall {
      --handle-offset: -24px;

      position: absolute;
      overflow: hidden;
      left: 0;
      top: 0;
      background: #fff;
      transition: opacity 1s ease-in-out;
      opacity: 0.9;

      &.active {
        overflow: visible;
        opacity: 1;
      }

      &.wall-2 {
        left: auto;
        right: 0;
      }

      .wall-overflow {
        overflow: hidden;
        position: absolute;
        inset: 0;
        z-index: 0;
      }

      .asset {
        display: inline-block;
        position: relative;
        cursor: move;

        &.ASSET_TEXT {
          > *.bold {
            font-weight: bold;
          }
          > *.italic {
            font-style: italic;
          }
          > *.underline {
            text-decoration: underline;
          }
        }
      }

      .boundingBox {
        position: absolute;
        z-index: 100;
      }

      .boundingBoxResizeHandle {
        position: absolute;
        width: 7px;
        height: 7px;
        background: #fff;
        border: 1px solid @boundingBoxColor;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: 1001;

        &::before {
          bottom: var(--handle-offset);
          content: "";
          display: block;
          left: var(--handle-offset);
          position: absolute;
          right: var(--handle-offset);
          top: var(--handle-offset);
        }
      }

      .boundingBoxCropHandle {
        position: absolute;
        background: #fff;
        border: 1px solid @boundingBoxColor;
        z-index: 1001;

        &::before {
          bottom: var(--handle-offset);
          content: "";
          display: block;
          left: var(--handle-offset);
          position: absolute;
          right: var(--handle-offset);
          top: var(--handle-offset);
        }
      }
    }

    // ASSETS PLACEHOLDERS
    [class^="placeholder-"] {
      background: #ddd;
      position: relative;
      box-shadow: inset 0 0 500px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    [class^="placeholder-"] svg {
      position: absolute;
      opacity: 0.5;
      width: 40px;
      height: 40px;
      margin: 0;
      top: auto;
      left: auto;
    }
  }
}
