.message {
  margin: 1rem 0;
  padding: 1rem 2rem 1rem 4.5rem;
  position: relative;
  font-size: 1.4rem;
  background-color: @c-info;
  color: @c-infoContrast;

  &.messageInfo {
    background-color: @c-info;
    color: @c-infoContrast;
  }

  &.messageWarning {
    background-color: @c-warning;
    color: @c-warningContrast;
  }

  &.messageError {
    background-color: @c-error;
    color: @c-errorContrast;
  }

  &.messageSuccess {
    background-color: @c-success;
    color: @c-successContrast;
  }

  .messageIcon {
    position: absolute;
    left: 1.1rem;
    top: 0.8rem;
    fill: currentColor;
    width: 24px;
  }

  .messageTitle {
    font-weight: @f-medium;
  }
  
  .messageBody {
    font-size: 0.9em;
    line-height: 1.3;
    margin-top: 0.3rem;
  }
}
