.cmsLogo {
  --is-collapsed: 0;

  --height: 2.444rem;
  --ratio: calc(312 / 85);
  --static: 40;
  --width: calc(var(--static) / 100 * var(--height) * var(--ratio));

  background: url("../../images/logo-hyro.svg") 0 50% / auto 100% no-repeat;
  cursor: pointer;
  display: block;
  height: var(--height);
  position: relative;
  width: var(--width);

  &::after {
    background: inherit;
    background-position: 102% 50%;
    bottom: 0;
    content: "";
    opacity: calc((-1) * var(--is-collapsed) + 1);
    position: absolute;
    right: 0;
    top: 0;
    transition-duration: 0.3s, 0.15s;
    transition-property: transform, opacity;
    transition-timing-function: ease-out, linear;
    transform: translateX(calc(100% + var(--is-collapsed) * 4rem));
    width: calc(100% + (100 - var(--static)) * 1%);
    will-change: opacity, transform;
  }
}
