#modal,
#legacy-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  transition: background 0.3s, width 0s 0.3s, height 0s 0.3s;
  z-index: 1000;

  &:not(:empty) {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    transition: background 0.3s, width 0s, height 0s;
  }

  .inputBox,
  .toggle {
    margin-right: 0;
    max-width: none;
  }

  .modalCloseOverlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.modalBox {
  background: @panelBackground;
  border-radius: 4px;
  width: 50rem;
  max-height: 96vh;
  max-width: 96vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  // support for multiple modals which overlays each other
  // pretty simple solution but backdrop can't be animated after close
  & ~ & {
    position: absolute;
    z-index: 2;
    max-width: 93vw;

    &::before {
      content: "";
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      animation: fadeIn 0.5s linear;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .modalHeader {
    flex: 0 0 6rem;
    line-height: 6rem;
    background: @c-white;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    padding: 0 6rem 0 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    position: relative;
    font-weight: @f-normal;
  }

  .modalContent {
    flex: 1 1 100%;
    padding: 2rem;
    overflow: auto;
    background: @panelBackground;
    position: relative;
  }

  .modalButtons {
    margin-top: 2rem;
    text-align: right;

    .left {
      float: left;
    }
  }

  .modalFooter {
    flex: 0 0 6rem;
    background: @c-white;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    padding: 0 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    position: relative;
    font-weight: @f-normal;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .left {
      margin-right: auto;
    }
  }

  .searchBox {
    width: 300px;
    max-width: 100%;
    margin: 1rem auto;
  }

  .inputBox {
    margin: 0 auto @base;
  }

  .listBox {
    @itemHeight: 50px;
    @itemRightMargin: 7px;

    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: @base*2;

    .ReactVirtualized__Grid {
      padding: 0;
    }

    .title {
      color: @c-denimLight;
      font-weight: @f-normal;
      font-size: 1.4rem;
      margin: 0;
      padding: 1rem;
    }

    .listNav,
    .tabNav {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 1rem;
      padding: 0;

      .active {
        color: @c-primary;
        border-bottom: 2px solid @c-primary;
      }

      .title,
      .item {
        padding: 0.57rem 2rem;
        list-style-type: none;
        cursor: pointer;
        &:hover {
          color: @c-primary;
        }
      }
    }

    .listPlaceholder {
      border: 1px solid @c-silver;
      color: @c-cloud;
      height: @itemHeight;
      line-height: @itemHeight;
      list-style-type: none;
      margin: 0 @itemRightMargin @itemRightMargin 0;
      border-radius: @input-border-radius;
      text-align: center;
    }

    .listSeparator {
      display: block;
      margin: 0 0 2rem 0;
      height: 2px;
      background: @c-pearl;
    }

    .listBoxItems {
      padding: 0;
      margin-right: -@itemRightMargin;
      font-size: 1.2rem;
      line-height: 1.35;

      &.listBoxItemsScroll {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 20rem;
      }

      .item {
        background: @c-white;
        border: 1px solid @c-cloud;
        border-radius: @input-border-radius;
        list-style-type: none;
        display: flex;
        height: @itemHeight;
        margin: 0 @itemRightMargin @itemRightMargin 0;
        overflow: hidden;

        .itemCell {
          flex: 1 1 100%;
          margin: 0.8rem 1.5rem 0.3rem;
          display: flex;
          flex-direction: column;
          align-items: baseline;
          overflow: hidden;

          .itemTitle {
            font-size: 1.3rem;
          }
          &.itemTitle {
            font-size: 1.3rem;
            font-weight: @f-medium;
            align-self: center;
            margin-bottom: 1rem;
            overflow: hidden;
            max-height: 34px;
          }

          .itemDescription {
            color: @c-denimLight;
          }
        }

        .itemCellRight {
          flex: 0 0 9rem;
          color: @c-cloud;
          border-left: 1px solid @c-cloud;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;
          text-align: center;
          line-height: 1.2;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          background: none;
          padding: 0;
          margin: 0;
          border-radius: 0;
          width: 100%;
          height: 100%;
          max-height: 100%;
          text-align: center;
          font-size: 1.3rem;
          color: @c-primary !important;

          svg {
            fill: currentColor;
            margin: 0 0.5rem 0 -0.3rem;
          }
        }

        .buttonRemove {
          flex-basis: @itemHeight;

          svg {
            fill: @c-error;
            margin: 0;
          }
        }
      }
    }
  }

  &.modalBoxSm {
    width: 40rem;
  }
}

.modalBox {
  &.is-bleeding {
    &,
    .modalContent {
      overflow: visible;
    }
  }
}

.modalConfirm .modalFooter button + button {
  margin-left: auto;
}

.modalConfirmForm {
  margin-top: 2rem;
}

.modalOverflowVisible {
  overflow: visible;

  .modalContent {
    overflow: visible;
  }
}

/// boostrap overide modal styles

.modal-title {
  color: @c-denimLight;
  font-weight: @f-normal;
  font-size: @base*1.8;
}

.modal-content {
  border-radius: 0 !important;
}

.modal-header {
  border: none;
  padding: @base*2;
}

.modal-body,
.modal-footer {
  border: none;
  padding: @base*2;
  background-color: @panelBackground;
}

.modal-footer .btn-toolbar {
  display: flex;
  justify-content: space-between;

  button:nth-child(2) {
    margin-left: auto;
  }

  .btn-cancel {
    background-color: @c-warningContrast;
    border-color: @c-warning;
    color: @c-warning;
  }

  .btn-save {
    background-color: @c-errorContrast;
    border-color: @c-error;
    color: @c-error;
  }
}

.modal-header .close {
  > span {
    display: none;
  }

  position: absolute;
  right: @base*2;
  top: @base*2;
  width: @base*2;
  height: @base*2;
  background: none;
  border: none;
  box-shadow: none;

  &:after,
  &:before {
    content: "";
    height: @base*0.2;
    width: @base*2;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 2px;
    transition: all 0.3s ease-in;
    background-color: @c-denimLight;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:hover {
    &:after {
      transform: rotate(-45deg);
      background-color: @c-primary;
    }

    &:before {
      transform: rotate(45deg);
      background-color: @c-primary;
    }
  }
}

.legacyConfirmationModal {
  .buttonWrapper {
    display: flex;

    .left {
      flex: 1;
    }
    .right {
      width: 80px;
    }
  }
}
