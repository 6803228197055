.uploadingBar {
  position: fixed;
  left: -100vw;
  top: 0;
  z-index: 10000;
  width: 101vw;
  height: 3px;
  background: @c-secondaryDark;
  transition: transform 0.5s;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    left: -300px;  
    top: 0;
    width: 300px;
    height: 100%;
    background: linear-gradient(90deg, transparent 0%, rgba(255,255,255,0.7) 50%, transparent 100%) no-repeat;
    animation: movebar 5s linear infinite;
  }
}

@keyframes movebar {
  0% {
    transform: translate3d(0, 0, 0)
  }
  50% {
    transform: translate3d(calc(101vw + 300px), 0, 0)
  }
  50.01% {
    transform: translate3d(0, 0, 0)
  }
}

// circular loading indicator
.preload {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 3rem;
  height: 3rem;
  margin: -1.5rem 0 0 -1.5rem;
  border-top-color: rgba(0,0,0,0.3);
  border-left-color: rgba(0,0,0,0.3);
  animation: preload 1s linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 0.2rem;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  transition: opacity 0.3s linear;
}

@keyframes preload {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
