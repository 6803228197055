.rc-tooltip {
  background: none !important;

  .rc-tooltip-inner {
    border-color: rgba(177, 177, 177, 0.6);
  }

  .rc-tooltip-arrow {
    opacity: 0.6;
  }
}

.rc-tooltip.context-menu {
  &.rc-tooltip-placement-right .rc-tooltip-arrow,
  &.rc-tooltip-placement-left .rc-tooltip-arrow {
    border-left-color: @secondaryColor;
    border-right-color: @secondaryColor;
  }
  &.rc-tooltip-placement-bottom .rc-tooltip-arrow,
  &.rc-tooltip-placement-top .rc-tooltip-arrow {
    border-top-color: @secondaryColor;
    border-bottom-color: @secondaryColor;
  }
  &.rc-tooltip-placement-bottomRight .rc-tooltip-arrow,
  &.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  &.rc-tooltip-placement-topRight .rc-tooltip-arrow,
  &.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
    display: none;
  }
  .rc-tooltip-inner {
    background: @secondaryColor;
    border: none;
    padding: 0;
    border-radius: 0;
    max-height: 70vh;
    overflow-y: auto;

    .list {
      & > button,
      & > div {
        font-size: 1.1em;
        display: block;
        white-space: nowrap;
        background: transparent;
        color: @secondaryContrastColor;
        border: none;
        text-align: left;
        width: 100%;
      }

      & > button {
        line-height: 22px;
        padding: 3px 10px;

        &:hover {
          background: lightgrey;
          color: black;
        }
      }
    }
  }

  .rc-tooltip-arrow {
    opacity: 1;
  }
}

.context-menu-trigger {
  all: unset;
}
