/**
 * Toast.
 * @see https://github.com/fkhadra/react-toastify
 */

.Toastify {
  .Toastify {
    &__toast {
      line-height: 1.25;
      min-height: 5rem;
      padding: 0.7rem 0.7rem 0.7rem 1.5rem;

      &--success {
        background: darken(@c-success, 10%);
      }
      &--error {
        background-color: @c-error;
      }
      &--info {
        background-color: @c-primary;
      }
      &--warning {
        background-color: @c-warning;
      }
    }

    &__close-button {
      color: transparent;
      margin-left: 0.5rem;
      position: relative;

      &::after,
      &::before {
        background: @c-white;
        content: "";
        height: 2px;
        left: calc(50% - 8px);
        position: absolute;
        top: calc(50% - 1px);
        transform: rotate(45deg);
        width: 16px;
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
