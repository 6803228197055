/**
 * Component Number.
 */

// =============================================================================
// Number
// =============================================================================

/**
 * 1. Designed as 13px.
 * 2. Designed as 36px, normalized.
 * 3. Designed as 16px.
 * 4. Designed as 180, normalized.
 */

.nymber {
  --color--active: @c-blueDodger;
  --color--danger: @alertColor;
  --color--initial: @input-c-border;
  --color-text--active: @c-white;
  --color-text--initial: @input-c-text;

  --nymber-animation-duration: 0.2s;
  --nymber-animation-timing: ease-in-out;
  --nymber-background: @c-white;
  --nymber-border-radius: 4px;
  --nymber-border-size: 1px;
  --nymber-color: var(--color--initial);
  --nymber-font-size: 1.444rem; /* 1 */
  --swytch-font-weight: @f-medium;
  --nymber-height: var(--nymber-size, @input-height); /* 2 */
  --nymber-indent: 1.778rem; /* 3 */
  --nymber-text-color: var(--color-text--initial);
  --nymber-width: 100%; /* 4 */

  --nymber-control-background: transparent;
  --nymber-control-color: inherit;
  --nymber-control-size: 22px;

  background-color: var(--nymber-background);
  border: var(--nymber-border-size) solid var(--nymber-color);
  border-radius: 4px;
  color: var(--color-text--initial);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--nymber-font-size);
  font-weight: var(--nymber-font-weight);
  height: var(--nymber-height);
  line-height: calc(var(--nymber-height) - 2 * var(--nymber-border-size));
  justify-content: space-between;
  overflow: hidden;
  transition-duration: var(--nymber-animation-duration);
  transition-property: border-color;
  transition-timing-function: var(--nymber-animation-timing);
  width: var(--nymber-width);

  [type="text"] {
    appearance: textfield;
    background: none;
    border: 0;
    border-radius: 0;
    flex: 1;
    height: inherit;
    margin: 0 calc((-1) * var(--nymber-indent));
    outline: 0;
    padding: inherit;
    width: 100%;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
    }
  }

  .nymber__body,
  .nymber__body::before,
  [type="text"] {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

/**
 * States.
 */

/**
 * Number, active.
 */

.nymber {
  &:active,
  &:focus,
  &:hover {
    &:not(.is-disabled) {
      --nymber-color: var(--color--active);
    }
  }
}

/**
 * Number, disabled.
 */

.nymber {
  &.is-disabled {
    pointer-events: none;
  }
}

/**
 * Number, invalid.
 */

.nymber {
  &.is-invalid {
    --nymber-color: var(--color--danger);
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Body
// TODO: Replace pseudoelement with a `placeholder` element
//  to address the overflow issue.
// =============================================================================

.nymber__body {
  display: flex;
  flex: 1;
  padding: 0 var(--nymber-indent);
  position: relative;
  z-index: 1;

  &::before {
    content: attr(data-value);
    height: 100%;
    left: 0;
    overflow: hidden;
    padding: inherit;
    position: absolute;
    max-width: 100%;
    z-index: -1;
  }
}

// Controls
// =============================================================================

.nymber__controls {
  display: flex;
  user-select: none;

  svg {
    width: 16px;
  }
}

// Control
// =============================================================================

.nymber__control {
  background-color: var(--nymber-control-background);
  color: var(--nymber-control-color);
  cursor: pointer;
  display: grid;
  place-items: center;
  transition-duration: var(--nymber-animation-duration);
  transition-property: background-color, color;
  transition-timing-function: var(--nymber-animation-timing);
  width: var(--nymber-control-size);
}

/**
 * Number control, hovered.
 */

.nymber__control {
  &:hover {
    --nymber-control-background: var(--color--active);
    --nymber-control-color: var(--color-text--active);
  }
}
