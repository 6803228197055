.flash-message {
  margin: 1em 0;
  border-radius: 5px;
  padding: 1.1em;
  position: relative;

  &.flash-message-small {
    padding: 0.5em 1em;
    margin: 1em 0 0;
  }

  &.flash-message-info {
    background-color: @c-warning;
    color: @c-warningContrast;
    padding-right: 3em;
  }

  &.flash-message-error {
    background-color: @c-error;
    color: @c-errorContrast;
  }

  &.flash-message-success {
    background-color: @c-success;
    color: @c-successContrast;
  }

  .flash-message__close-button {
    color: inherit;
    font-size: 2em;
    display: block;
    position: absolute;
    right: 0.3em;
    top: 0.15em;
    text-decoration: none;
    transform: rotate(45deg);
  }

  &.flash-message-error > .flash-message__close-button {
    display: none;
  }
}
