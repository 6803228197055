.header {
  align-items: center;
  background: @c-contentBackground;
  border-bottom: 1px solid @c-contentBorder;
  display: flex;
  flex: 0 0 @headerHeight;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px 2rem;
  position: relative;
  z-index: @z-header;

  & > div {
    align-items: center;
    display: flex;

    &:nth-of-type(1) {
      align-items: baseline;
      width: ~"min(100%, 30vw)";
    }
  }
}

.appName {
  cursor: pointer;
}

.appName,
.appNameSecondary {
  color: @c-primaryText;
  font-size: 1.5rem;
  font-weight: @f-bold;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    box-sizing: content-box;
    padding: 8px;
    display: inline-flex;
    align-content: center;
    justify-content: space-around;
    background: @c-pearl;
    fill: currentColor;
    border-radius: 50%;
    margin: 0 0.8rem 0 0;
    transition: all 0.15s linear;
  }
}

.appNameSecondary {
  align-items: center;
  color: @c-secondaryText;
  display: flex;
  flex-shrink: 9e9;
  pointer-events: none;

  &::before {
    content: "|";
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
}

.appNameBack {
  margin-left: 0.4rem;
  height: 32px;
  border-radius: 16px;
  transition: all 0.15s;
  display: inline-flex;
  align-items: center;

  &:hover {
    padding-right: 12px;
    background: @c-primaryLight;

    svg {
      background: @c-primary;
      color: @c-white;
    }
  }
}

// User menu
// =============================================================================

[data-dropdown="toggle"] {
  place-items: center;
  &[class] {
    display: grid;
    svg {
      height: 14px;
    }
  }
}

.dropdown {
  @backgroundColor: @c-white;

  opacity: 1;

  [class*="content"] {
    filter: drop-shadow(0 2px 12px rgba(@c-black, 0.125));
    transform: translate(1px, 2px);
  }

  [class*="inner"] {
    background-color: @backgroundColor;
    border: 0;
    border-radius: 2px;
    font-size: 1.333rem;
    font-weight: @f-medium;
    min-width: 14rem;
    padding: 1.778rem;
    padding-top: 1.333rem;
    position: relative;
    z-index: 1;
  }

  [class*="arrow"] {
    @size: 0.9rem;

    background-color: @backgroundColor;
    border: 0;
    border-radius: 1px;
    display: inline-block;
    height: @size;
    margin: 0;
    opacity: inherit;
    transform: translate(110%, 30%) scaleY(1.25) rotate(45deg);
    width: @size;
  }

  button {
    align-items: center;
    color: @c-primaryText;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    font-weight: inherit;
    transition: color 0.3s;
    width: 100%;

    & + button {
      margin-top: 1.333rem;
    }

    &:hover {
      color: @c-primary;
    }
  }
}
