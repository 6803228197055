.filtersAndSearch {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
}

.filters {
  flex: 1;
  min-width: 15rem;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 1.5rem auto 0 2rem;

  &.collapsible {
    padding-right: 10rem;
  }

  &.collapsed {
    height: calc(@input-height + 1rem);
  }

  .filterSwitch {
    all: unset;
    position: absolute;
    right: 1rem;
    top: 0;
    height: 4rem;
    margin-left: 3rem;
    text-align: right;
    cursor: pointer;
    font-weight: @f-medium;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  & > .context-menu-trigger {
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
  }

  .filterButton {
    margin: 0 1rem 1rem 0 !important;
    padding-right: 3.5rem;

    &:not(:hover):not(.isActive) {
      border-color: @c-cloud !important;
      color: @c-denim !important;
      background: transparent !important;
    }
    &.isActive:not(:hover) {
      color: @c-denim !important;
    }

    &::before {
      content: "";
      position: absolute;
      right: 0.9rem;
      top: 50%;
      width: 0.9rem;
      height: 0.9rem;
      border: 0.2rem solid transparent;
      border-color: transparent @c-primary @c-primary transparent;
      border-radius: 0.2rem;
      box-sizing: border-box;
      transform: rotate(45deg) translateX(-1rem);
      transition: border 0.2s ease;
    }

    &:hover::before {
      border-color: transparent @c-primaryContrast @c-primaryContrast
        transparent;
    }
  }
}

.filterTags {
  flex: 0 0 100%;
  padding: 0 2rem;

  .filterTag {
    height: 2.6rem;
    border-radius: 1.3rem;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0 1.5rem 0 0.3rem;
    font-size: 1.2rem;
    background-color: @c-primary;
    color: @c-primaryContrast;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30rem;
    vertical-align: top;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    .clearIcon {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      margin-right: 0.8rem;
      border-radius: 50%;
      background: @c-primaryContrast;
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 1rem;
        height: 0.2rem;
        border-radius: 0.1rem;
        background: @c-primary;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    &:hover .clearIcon::before,
    &:hover .clearIcon::after {
      background: darken(@c-error, 10%);
    }
  }

  .clearAll {
    font-weight: @f-medium;
    text-decoration: underline;
    position: relative;
    top: 0.3rem;

    &:hover {
      text-decoration: none;
    }
  }
}

.filterButton.noResult {
  display: none;
}
.filterContextMenu {
  opacity: 1 !important;

  .searchInput {
    display: block;
    width: 100%;
    height: 4rem;
    border: none;
    border-bottom: 1px solid @input-c-border;
    position: sticky;
    top: 0;
    left: 0;
    outline: none !important;
    color: @c-primaryText;
    padding: 0 2rem;
    z-index: 10;
    font-weight: @f-medium;
  }

  &.rc-tooltip {
    opacity: 1;

    &.rc-tooltip.context-menu .rc-tooltip-inner {
      background: @c-white;
      box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15);
      position: relative;
      overflow-y: auto;
      min-width: 15rem;
      padding-bottom: 1rem;
      border-radius: 0.3rem;

      .list button {
        color: @c-primaryText;
        padding: 1rem 2rem;
        margin-bottom: -0.3rem;
        cursor: pointer;
        display: block;
        width: 100%;
        text-align: left;
        line-height: 1.6rem;
        max-width: 30rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          background: @c-primaryLight;
        }

        &:first-of-type {
          margin-top: 0.7rem;
        }
      }
    }

    &.context-menu.rc-tooltip-placement-bottom .rc-tooltip-arrow,
    &.context-menu.rc-tooltip-placement-top .rc-tooltip-arrow {
      top: -0.6rem;
      margin-left: -0.8rem;
      border-width: 0 0.8rem 0.8rem;
      border-bottom-color: @c-white;
      z-index: 10;
    }
    &.context-menu.rc-tooltip-placement-top .rc-tooltip-arrow {
      top: auto;
      bottom: -0.6rem;
      border-width: 0.8rem 0.8rem 0;
      border-top-color: @c-white;
    }
  }

  .check {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.3rem;
    border: 1px solid @c-primary;
    margin-right: 1rem;
    box-sizing: border-box;
    position: relative;
    vertical-align: top;

    &.checked {
      background: @c-primary;
    }

    svg {
      position: absolute;
      left: -1px;
      top: -1px;
      width: inherit;
      height: inherit;
      fill: @c-primaryContrast;
    }
  }

  .loading,
  .noResult {
    color: @c-secondaryText;
    text-align: center;
    margin: 2.5rem 1.5rem 1.5rem 1.5rem;
  }
}
