.breadcrumb {
  padding: (@base*0.8) (@base*1.5);
  margin-bottom: (@base*2);
  list-style: none;
  background-color: transparent;

  .item {
    &:before {
      padding: 0 (@base*0.5);
      color: @c-denimLight;
      content: "/\00a0";
    }
  }
}