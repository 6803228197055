div.combobox__control {
  border: 1px solid @input-c-border;
  box-shadow: none;
  border-radius: @input-border-radius;
  transition: all 0.15s ease-in-out;
  min-height: @input-height;
  cursor: text;

  &:hover {
    border-color: @input-c-border-active;
  }
}

div.combobox__control--is-focused {
  border-color: @input-c-border-active !important;
}

div.combobox__multi-value {
  background: @c-primaryLight;
  margin: 3px 2px 0;
}
div.combobox__value-container {
  padding: 2px 4px 4px;

  [class$="-Input"] {
    margin: 0 2px;
  }
}

div.combobox__dropdown-indicator {
  width: @input-height;
  height: calc(100% + 2px);
  position: relative;
  right: -1px;
  padding: 0;
  background-color: @c-primary;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMT\
    IgNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSIjZmZ\
    mZmZmIiBkPSJNMC4yNSwxLjUzbDUuMTQsNS4xNGMwLjM0LDAuMzQsMC44OCwwLjM0LDEuMjEs\
    MGw1LjE0LTUuMTRjMC4zNC0wLjM0LDAuMzQtMC44OCwwLTEuMjFzLTAuODgtMC4zNC0xLjIxL\
    DBMNiw0Ljg2TDEuNDYsMC4zMmMtMC4zNC0wLjM0LTAuODgtMC4zNC0xLjIxLDBTLTAuMDgsMS\
    4yLDAuMjUsMS41M0wwLjI1LDEuNTN6Ii8+PC9zdmc+');
  background-position: center center;
  background-size: 1.2rem 0.7rem;
  background-origin: border-box;
  background-repeat: no-repeat;
  border-radius: 0 @input-border-radius @input-border-radius 0;
  cursor: pointer;

  svg {
    display: none;
  }
}

span.combobox__indicator-separator {
  display: none;
}

div.combobox__clear-indicator {
  padding: 6px 8px;
  cursor: pointer;
}

div.combobox__placeholder {
  margin-left: 0.8rem;
}

div.combobox__menu {
  margin-top: 0.4rem;
}

div.combobox__multi-value__label,
div.combobox__multi-value__remove {
  border: 1px solid lighten(@c-primary, 30%);
  color: #007EFE;
  padding: 3px 8px 1px 8px;
  cursor: default;
}

div.combobox__multi-value__remove {
  margin-left: -1px;
  padding: 0 3px;
  cursor: pointer;

  &:hover {
    background-color: lighten(@c-error, 30%);
    border-color: @c-error;
    color: @c-error;
  }
}

.combobox {
  &.is-static {
    [class*="menu"] {
      position: relative;
    }
  }
}
